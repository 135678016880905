import { useCallback, useEffect, useRef } from "react";

export const useTimer = (delay: number, callback: () => void) => {
  const savedCallback = useRef<() => void | null>();
  const savedTimer = useRef<NodeJS.Timeout | null>();
  const savedDelay = useRef<number | null>();

  const doAction = useCallback(() => {
    if (typeof savedCallback?.current !== "undefined") {
      savedCallback?.current();
    }
  }, []);

  useEffect(() => {
    savedCallback.current = callback;
    savedDelay.current = delay;
    savedTimer.current = setTimeout(doAction, savedDelay.current);
    return () => {
      clearTimeout(savedTimer.current!);
    };
  }, [delay]);

  const reset = useCallback(() => {
    if (savedTimer.current) {
      clearTimeout(savedTimer.current);
      savedTimer.current = setTimeout(doAction, savedDelay.current!);
    }
  }, []);

  return [reset];
};
