import { Button, Grid } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import React, { useEffect } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { InactiveComponent } from '../../components/InactiveComponent'
import { actions } from '../../saga/auth'
import { AppState } from '../../saga/store'

const ChooseVidp = (props: { lang: string; spId: string }) => {
    const { spId, lang } = { ...props }

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(actions.text.request({ spId, lang }))
        dispatch(actions.vidps.request({ spId, lang }))
    }, [])

    const vidps = useSelector((s: AppState) => s.auth.vidps)
    const texts = useSelector((s: AppState) => s.auth.text)

    const handleCancel = () => {
        dispatch(actions.cancelDs.request({ spId: props.spId }))
    }

    return (
        <InactiveComponent spId={spId}>
            {texts !== undefined && (
                <IntlProvider messages={texts} locale={props.lang}>
                    {vidps.length > 0 && (
                        <Grid className="Centred-Content" item xs={12}>
                            {
                                <Grid container spacing={1}>
                                    <Grid className="Centred-Content" item xs={12}>
                                        <span className="idp-header idp-header-info">
                                            <FormattedMessage id="IN_CHOOSE_LOGIN_METHOD" />
                                        </span>
                                    </Grid>
                                    {vidps.map((v, i) => {
                                        const icon = v.icon ? (
                                            <img
                                                style={{
                                                    height: v.icon.height,
                                                    width: v.icon.width,
                                                }}
                                                src={v.icon.url}
                                            />
                                        ) : (
                                            <div />
                                        )
                                        return (
                                            <Grid key={`vidp_${i}`} item xs={12}>
                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    onClick={() =>
                                                        window.location.replace(v.redirectUrl)
                                                    }
                                                    className="idp-btn idp-btn-select-login-method idp-btn-select-login-method-ds"
                                                    startIcon={icon}
                                                    endIcon={<ChevronRightIcon />}>
                                                    <Grid container justifyContent="flex-start">
                                                        <Grid item>{v.name}</Grid>
                                                    </Grid>
                                                </Button>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            }
                        </Grid>
                    )}
                    <Grid className="Filler" item xs={12}></Grid>
                    <div className="Bottom-Content">
                        {
                            <Button fullWidth className="idp-btn idp-btn-cancel idp-btn-cancel-ds" variant="outlined" onClick={handleCancel}>
                                <FormattedMessage id="LA_CANCEL" />
                            </Button>
                        }
                    </div>
                </IntlProvider>
            )}
        </InactiveComponent>
    )
}
export default ChooseVidp
