/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BankIdAuthParams,
    BankIdAuthParamsFromJSON,
    BankIdAuthParamsToJSON,
    StatusInfo,
    StatusInfoFromJSON,
    StatusInfoToJSON,
} from '../models';

export interface BankIdAuthRequest {
    aid: string;
    id: string;
    lang: string;
    bankIdAuthParams?: BankIdAuthParams;
}

export interface BankIdSignRequest {
    aid: string;
    id: string;
    lang: string;
    bankIdAuthParams?: BankIdAuthParams;
}

export interface CancelBankIdSessionRequest {
    aid: string;
}

export interface GetAutoStartTokenRequest {
    aid: string;
}

export interface GetBankIdQrValueRequest {
    aid: string;
}

export interface GetBankIdStatusRequest {
    aid: string;
}

/**
 * 
 */
export class BankidApi extends runtime.BaseAPI {

    /**
     */
    async bankIdAuthRaw(requestParameters: BankIdAuthRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.aid === null || requestParameters.aid === undefined) {
            throw new runtime.RequiredError('aid','Required parameter requestParameters.aid was null or undefined when calling bankIdAuth.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling bankIdAuth.');
        }

        if (requestParameters.lang === null || requestParameters.lang === undefined) {
            throw new runtime.RequiredError('lang','Required parameter requestParameters.lang was null or undefined when calling bankIdAuth.');
        }

        const queryParameters: any = {};

        if (requestParameters.aid !== undefined) {
            queryParameters['aid'] = requestParameters.aid;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/id/bankid/auth`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BankIdAuthParamsToJSON(requestParameters.bankIdAuthParams),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async bankIdAuth(aid: string, id: string, lang: string, bankIdAuthParams?: BankIdAuthParams): Promise<string> {
        const response = await this.bankIdAuthRaw({ aid: aid, id: id, lang: lang, bankIdAuthParams: bankIdAuthParams });
        return await response.value();
    }

    /**
     */
    async bankIdSignRaw(requestParameters: BankIdSignRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.aid === null || requestParameters.aid === undefined) {
            throw new runtime.RequiredError('aid','Required parameter requestParameters.aid was null or undefined when calling bankIdSign.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling bankIdSign.');
        }

        if (requestParameters.lang === null || requestParameters.lang === undefined) {
            throw new runtime.RequiredError('lang','Required parameter requestParameters.lang was null or undefined when calling bankIdSign.');
        }

        const queryParameters: any = {};

        if (requestParameters.aid !== undefined) {
            queryParameters['aid'] = requestParameters.aid;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/id/bankid/sign`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BankIdAuthParamsToJSON(requestParameters.bankIdAuthParams),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async bankIdSign(aid: string, id: string, lang: string, bankIdAuthParams?: BankIdAuthParams): Promise<string> {
        const response = await this.bankIdSignRaw({ aid: aid, id: id, lang: lang, bankIdAuthParams: bankIdAuthParams });
        return await response.value();
    }

    /**
     */
    async cancelBankIdSessionRaw(requestParameters: CancelBankIdSessionRequest): Promise<runtime.ApiResponse<StatusInfo>> {
        if (requestParameters.aid === null || requestParameters.aid === undefined) {
            throw new runtime.RequiredError('aid','Required parameter requestParameters.aid was null or undefined when calling cancelBankIdSession.');
        }

        const queryParameters: any = {};

        if (requestParameters.aid !== undefined) {
            queryParameters['aid'] = requestParameters.aid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/id/bankid/cancel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusInfoFromJSON(jsonValue));
    }

    /**
     */
    async cancelBankIdSession(aid: string): Promise<StatusInfo> {
        const response = await this.cancelBankIdSessionRaw({ aid: aid });
        return await response.value();
    }

    /**
     */
    async getAutoStartTokenRaw(requestParameters: GetAutoStartTokenRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.aid === null || requestParameters.aid === undefined) {
            throw new runtime.RequiredError('aid','Required parameter requestParameters.aid was null or undefined when calling getAutoStartToken.');
        }

        const queryParameters: any = {};

        if (requestParameters.aid !== undefined) {
            queryParameters['aid'] = requestParameters.aid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/id/bankid/getAutoStartToken`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async getAutoStartToken(aid: string): Promise<string> {
        const response = await this.getAutoStartTokenRaw({ aid: aid });
        return await response.value();
    }

    /**
     */
    async getBankIdQrValueRaw(requestParameters: GetBankIdQrValueRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.aid === null || requestParameters.aid === undefined) {
            throw new runtime.RequiredError('aid','Required parameter requestParameters.aid was null or undefined when calling getBankIdQrValue.');
        }

        const queryParameters: any = {};

        if (requestParameters.aid !== undefined) {
            queryParameters['aid'] = requestParameters.aid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/id/bankid/qr`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async getBankIdQrValue(aid: string): Promise<string> {
        const response = await this.getBankIdQrValueRaw({ aid: aid });
        return await response.value();
    }

    /**
     */
    async getBankIdStatusRaw(requestParameters: GetBankIdStatusRequest): Promise<runtime.ApiResponse<StatusInfo>> {
        if (requestParameters.aid === null || requestParameters.aid === undefined) {
            throw new runtime.RequiredError('aid','Required parameter requestParameters.aid was null or undefined when calling getBankIdStatus.');
        }

        const queryParameters: any = {};

        if (requestParameters.aid !== undefined) {
            queryParameters['aid'] = requestParameters.aid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/id/bankid/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusInfoFromJSON(jsonValue));
    }

    /**
     */
    async getBankIdStatus(aid: string): Promise<StatusInfo> {
        const response = await this.getBankIdStatusRaw({ aid: aid });
        return await response.value();
    }

}
