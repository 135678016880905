import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { StatusInfo } from "../generated/api";
import { AuthStatus } from "../saga/auth";
import { AppState } from "../saga/store";

export interface InfoMessageProps {
  qr: boolean;
  pnr?: boolean;
  sameDevice: boolean;
  loggedInMessage: string;
  scanQrMessage: string;
  startAppMessage?: string;
  textClassName?: string
  hideQrMessage?: (statusInfo: StatusInfo) => boolean;
}

const InfoMessage = (props: InfoMessageProps) => {
  const status = useSelector<AppState, AuthStatus>((s) => s.auth.authStatus);
  const statusInfo = useSelector<AppState, StatusInfo>(
    (s) => s.auth.statusInfo
  );
  const getInfoMessage = (): string | undefined => {
    if (status === AuthStatus.COMPLETED) {
      return props.loggedInMessage;
    } else if (status === AuthStatus.PENDING) {
      if (props.qr) {
        return !props.hideQrMessage || !props.hideQrMessage(statusInfo)
          ? props.scanQrMessage
          : undefined;
      } else if (props.pnr) {
        return props.startAppMessage;
      }
    }
    return undefined;
  };

  const [infoMsg, setInfoMsg] = useState<string | undefined>(undefined);
  useEffect(() => {
    setInfoMsg(getInfoMessage());
  }, [props, status, statusInfo]);
  return (
    <>
      {infoMsg && (
        <Grid className="Centred-Content" item xs={12}>
          <p>
            <span className={props.textClassName}>
              <FormattedMessage id={infoMsg} />
            </span>
          </p>
          <br />
        </Grid>
      )}
    </>
  );
};
export default InfoMessage;
