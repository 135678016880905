import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import InfoMessage from '../../components/InfoMessage'
import StartBankIdButton from '../../components/StartIdAppButton'
import Status from '../../components/Status'
import { AutoStartConfiguration, LoginMethodInfoObject, StatusInfo } from '../../generated/api'
import { webApiClient } from '../../saga/api'
import { actions, AuthStatus } from '../../saga/auth'
import { AppState } from '../../saga/store'
import {
    addRfaSuffix,
    ERROR_RFA_CODES,
    getBankidAutoStartToken,
    getBankIdPromise,
    getStartBankIdAppUrl,
    hideQr,
} from '../../util/bankIdUtils'
import { getUA, isAndroid, isChrome, isIOS } from 'react-device-detect'
import { CircularProgress } from '@material-ui/core'

interface LoginBankIdProps {
    aid: string
    lang: string
    pnrMode: boolean
    qrMode: boolean
    allowHandleBack: boolean
}

export default (props: LoginBankIdProps) => {
    const { aid, lang, pnrMode, qrMode, allowHandleBack } = { ...props }
    const dispatch = useDispatch()
    const history = useHistory()
    const [autostarttoken, setAutoStartToken] = useState<string | undefined>(undefined)
    //Using states to keep track of active timer and when a new promise is available.
    const [autoStartTokenPromise, setAutoStartTokenPromise] = useState<Promise<string>>()
    const [autoStartTokenTimer, setAutoStartTokenTimer] = useState<NodeJS.Timeout>()
    const [autoStartConfig, setAutoStartConfig] = useState<AutoStartConfiguration | undefined>()
    const selectedMethod = useSelector<AppState, LoginMethodInfoObject | undefined>(
        (s) => s.auth.selectedMethod
    )
    //Boolean is used to know if this loginmethod was the only one available.
    const loneMethod = useSelector<AppState, boolean | undefined>(
        (s) => s.auth.methods.length === 1 ? true : false
    )

    useEffect(() => {
        if (!pnrMode || selectedMethod?.providedIdentification) {
            handleLogin(selectedMethod?.providedIdentification)
        };
        //When we leave the BankID login page, stop/clear the active timer.
        return () => {
            if(autoStartTokenTimer){
            clearTimeout(autoStartTokenTimer);
            }
        }
    }, [selectedMethod, pnrMode])

    //Whenever the autostarttoken is changed, this method will execute.
    useEffect(() => {
        //If sameDevice is true and there is an autostarttoken (that is, the initial attempt has succeeded),
        //it will start an timer to check for new autoStartTokens from core web. There is an initial clearTimeout
        //set to 28,5s to deal with some latency.
        if(sameDevice && autostarttoken){
            setAutoStartTokenTimer(setTimeout(() => {getRemoteAutoStartToken()},28500))
        }
    }, [autostarttoken])

    //Whenever the autoStartTokenPromise is changed, that is a new request is made to core, this method will execute.
    useEffect(() => {
        //When the Promise gets fulfilled and a response is received, it will check with the old value if it's the same or not.
        autoStartTokenPromise?.then((response) => {
            //If it's not the same, it will start a new timer set to 2s to
            if(autostarttoken === response){ 
                setAutoStartTokenTimer(setTimeout(() => {getRemoteAutoStartToken()},2000))
            } else{
                setAutoStartToken(response)
                
            }
        })
    }, [autoStartTokenPromise])
    //Method that calls for a new Promise for a autoStartToken and sets the Promise to the state.
    const getRemoteAutoStartToken = () => {
        setAutoStartTokenPromise(getBankidAutoStartToken(aid))
    }

    const config: any = selectedMethod?.parameters
    const sameDevice = useMemo(() => config?.device === 'true', [config?.device])
    /* Boolean if browser should try to start BankID automatically.
    It needs to check if this is the only method active in vIdp, because
    if it's Chrome on Android, an autostart wouldn't return back to the correct
    page in core-web, but to the page that last had an interaction from the user,
    so it's disabled by default. Otherwise, it will check the config.*/
    const tryAutostart = useMemo(() => {return (loneMethod && isChrome && isAndroid) ? false : config?.tryAutostart === 'true'}, [config?.tryAutostart])
    const handleLogin = (personalNumber: string | undefined) => {
        if (!selectedMethod) {
            return
        }
        dispatch(actions.setStatus(AuthStatus.INITIATED))
        const bankIdPromise: Promise<string> = getBankIdPromise(
            aid,
            selectedMethod,
            lang
        )
        //Check if we (the end-user browser) are running on iOS due to iOS needing special behaviour for links to and from BankID app. AutoStartConfiguration is only needed for iOS.
        //Also checks if the login method is of type "sameDevice" as code below is only needed if ID provider and client accessing eid-verify is on same device.
        if(isIOS && sameDevice){
            const autoStartConfigPromise: Promise<AutoStartConfiguration> = webApiClient.getAutoStartConfiguration(getUA);
            Promise.all([bankIdPromise, autoStartConfigPromise]).then(responses => {
                setAutoStartToken(responses[0])
                dispatch(actions.setStatus(AuthStatus.PENDING))
                setAutoStartConfig(responses[1])
                if (tryAutostart) {
                    window.location.href = getStartBankIdAppUrl(responses[0], responses[1])
                }
            })
            .catch(() => dispatch(actions.setStatus(AuthStatus.ERROR)))
        }
        else {
            bankIdPromise
            .then((response) => {
                setAutoStartToken(response)
                dispatch(actions.setStatus(AuthStatus.PENDING))
                if (sameDevice && tryAutostart) {
                    window.location.href = getStartBankIdAppUrl(response, undefined)
                }
            })
            .catch(() => dispatch(actions.setStatus(AuthStatus.ERROR))) 
        }
    }

    const handleRFACodes = (statusInfo: StatusInfo) => {
        var message = statusInfo.message ? statusInfo.message : ''
        message = addRfaSuffix(message, qrMode)
        if (ERROR_RFA_CODES.includes(message)) {
            dispatch(actions.setErrorMsg(message))
            dispatch(
                actions.setStatus('RFA3' === message ? AuthStatus.CANCELLED : AuthStatus.ERROR)
            )
        }
        return message
    }

    return (
        <>
            {allowHandleBack && <InfoMessage
                qr={qrMode}
                sameDevice={sameDevice}
                loggedInMessage="IN_BANKID_LOGGED_IN"
                scanQrMessage="IN_BANKID_SCAN_QR"
                hideQrMessage={hideQr}
                textClassName="idp-header idp-header-info"
            />}
            {allowHandleBack && <Status
                aid={props.aid}
                qr={qrMode ? 'ANIMATED' : undefined}
                handleStatusMessage={handleRFACodes}
                statusAction={actions.bankIdStatus}
                qrAction={actions.bankIdQr}
                hideQr={hideQr}
            />}
            {allowHandleBack && <StartBankIdButton
                sameDevice={sameDevice}
                getStartIdAppUrl={getStartBankIdAppUrl}
                autostarttoken={autostarttoken}
                autoStartConfig={autoStartConfig}
                tryAutostart={tryAutostart}
                buttonLabel="RFA18"
                type="bankid"
            />}
            {!allowHandleBack && <CircularProgress />}
        </>
    )
}
