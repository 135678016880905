import { WebApi } from "./generated/api"

export interface ErrorReporter {
    (name: string, error: Error, refId?: string): Promise<void>
}

export const voidErrorReporter: ErrorReporter = (name: string, error: Error, refId?: string) => {
    console.log(`Error: ${error}, ref: ${refId ?? ''}`)
    return Promise.resolve()
}

export const getApiErrorReporter = (apiClient: WebApi): ErrorReporter => async (name: string, error: Error, refId?: string) => {
    if (error instanceof Response) {
        const body = JSON.stringify(error.json()).substr(0, 2000)
        const status = error.status;
        const msg = `ApiError with status: ${status}, body: ${body}`
        await apiClient.logError({ name: name, message: msg, refId: refId ?? '' })
    } else {
        await apiClient.logError({ name: name, message: error.message ?? 'no message', refId: refId ?? '' })
    }
}

