/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrejaInitParams
 */
export interface FrejaInitParams {
    /**
     * 
     * @type {string}
     * @memberof FrejaInitParams
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof FrejaInitParams
     */
    personalNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof FrejaInitParams
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof FrejaInitParams
     */
    orgId?: string;
    /**
     * 
     * @type {string}
     * @memberof FrejaInitParams
     */
    userInfoType?: string;
}

export function FrejaInitParamsFromJSON(json: any): FrejaInitParams {
    return FrejaInitParamsFromJSONTyped(json, false);
}

export function FrejaInitParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrejaInitParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'personalNumber': !exists(json, 'personalNumber') ? undefined : json['personalNumber'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'orgId': !exists(json, 'orgId') ? undefined : json['orgId'],
        'userInfoType': !exists(json, 'userInfoType') ? undefined : json['userInfoType'],
    };
}

export function FrejaInitParamsToJSON(value?: FrejaInitParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'personalNumber': value.personalNumber,
        'phone': value.phone,
        'orgId': value.orgId,
        'userInfoType': value.userInfoType,
    };
}


