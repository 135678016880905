import { AutoStartConfiguration, FrejaOrgIdInitParamsUserInfoTypeEnum, LoginMethodInfoObject, StatusInfo } from './../generated/api'
import { frejaOrgIdClient } from './../saga/api'
import { AuthStatus } from './../saga/auth'

export function hideQr(statusInfo: StatusInfo) {
    return statusInfo.substatus !== 'STARTED'
}
export const getStartFrejaAppUrl = (token: string | undefined, autoStartConfiguration : AutoStartConfiguration | undefined) => {
    let startUrl = 'frejaeid://bindUserToTransaction?transactionReference=' + token
    //If autoStartConfiguration exists and returnUrl is defined, build return URL.
    if(autoStartConfiguration?.returnUrl !== undefined){
        startUrl += '&originAppScheme=' + autoStartConfiguration.returnUrl + (autoStartConfiguration.appendBack ? window.location.href : '')
    }
    return startUrl;
}
export const getFrejaPromise = (
    aid: string,
    loginMethod: LoginMethodInfoObject,
    personalNumber: string | undefined,
    lang: string
): Promise<string> => {
    const userInfoType = personalNumber || loginMethod.providedIdentification ? FrejaOrgIdInitParamsUserInfoTypeEnum.Ssn : FrejaOrgIdInitParamsUserInfoTypeEnum.Inferred
    return loginMethod.signMesssage
        ? frejaOrgIdClient.frejaSign(aid, loginMethod.id!, lang, {
              userInfo: personalNumber,
              userInfoType: userInfoType,
          })
        : frejaOrgIdClient.startAuth(aid, loginMethod.id!, {
              userInfo: personalNumber,
              userInfoType: userInfoType,
          })
        
        
}

const CANCEL_STATUSES = ['CANCELED', 'RP_CANCELED']
const ERROR_STATUSES = ['CANCELED', 'RP_CANCELED', 'EXPIRED', 'REJECTED']

export function getCancelOrError(statusInfo: StatusInfo): AuthStatus {
    return CANCEL_STATUSES.includes(statusInfo.substatus!) ? AuthStatus.CANCELLED : AuthStatus.ERROR
}

export function isErrorStatus(statusInfo: StatusInfo) {
    return statusInfo.substatus && ERROR_STATUSES.includes(statusInfo.substatus)
}
