import { Button, Grid } from '@material-ui/core';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTimer } from '../util/useTimer';
import { AutoStartConfiguration } from '../generated/api';

export interface StartBankIdButtonProps {
    sameDevice: boolean
    tryAutostart: boolean
    autostarttoken: string | undefined
    autoStartConfig: AutoStartConfiguration | undefined
    getStartIdAppUrl: (autostarttoken: string | undefined, autoStartConfig: AutoStartConfiguration | undefined) => string | undefined
    buttonLabel: string
    type: "bankid" | "freja" | "nias" | "frejaorgid" 
}

const StartIdAppButton = (props: StartBankIdButtonProps) => {

    const [timeOut, setTimeOut] = useState<boolean>(false);
    useTimer(5000, () => setTimeOut(true))
    const showButton = useMemo(
        () =>
            (props.sameDevice && !props.tryAutostart) ||
            (props.sameDevice && props.tryAutostart && timeOut),
        [props, timeOut]
    )
    return (
        <Grid className="Centred-Content" item xs={12}>
            {showButton && (
                <Button
                    fullWidth
                    variant="contained"
                    className={`idp-btn idp-btn-start-id-${props.type}`}
                    href={props.getStartIdAppUrl(props.autostarttoken, props.autoStartConfig)}>
                    <FormattedMessage id={props.buttonLabel} />
                </Button>
            )}
        </Grid>
    )
}
export default StartIdAppButton
