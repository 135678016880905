import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import App from './App'
import './index.css'
import PageNotFound from './PageNotFound'
import { history, store } from './saga/store'

const f = () => {
    const a = document.getElementById('root')
    if (a) {
        ReactDOM.render(
            <React.StrictMode>
                <Provider store={store}>
                    <Router history={history}>
                        <App />
                    </Router>
                </Provider>
            </React.StrictMode>,
            document.getElementById('root')
        )

        // If you want to start measuring performance in your app, pass a function
        // to log results (for example: reportWebVitals(console.log))
        // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
        // reportWebVitals()
        return true
    }
    console.log('retry')
    return false
}
const f2 = () => {
    if (!f() && (document as any).__id_init_err === undefined) {
        setTimeout(() => {
            f2()
        }, 200)
    }

    if ((document as any).__id_init_err) {
        console.log('Error loading... abort')

        ReactDOM.render(
            <React.StrictMode>
                <Provider store={store}>
                    <Router history={history}>
                        <PageNotFound />
                    </Router>
                </Provider>
            </React.StrictMode>,
            document.getElementById('error')
        )
    }
}

f2()
