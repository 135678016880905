/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CancelInfo,
    CancelInfoFromJSON,
    CancelInfoToJSON,
} from '../models';

export interface CancelRequest {
    aid: string;
}

export interface CancelDsRequest {
    spId: string;
}

export interface FailRequest {
    aid: string;
}

export interface FinishRequest {
    aid: string;
}

/**
 * 
 */
export class IdControllerApi extends runtime.BaseAPI {

    /**
     */
    async cancelRaw(requestParameters: CancelRequest): Promise<runtime.ApiResponse<CancelInfo>> {
        if (requestParameters.aid === null || requestParameters.aid === undefined) {
            throw new runtime.RequiredError('aid','Required parameter requestParameters.aid was null or undefined when calling cancel.');
        }

        const queryParameters: any = {};

        if (requestParameters.aid !== undefined) {
            queryParameters['aid'] = requestParameters.aid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/id/cancel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CancelInfoFromJSON(jsonValue));
    }

    /**
     */
    async cancel(aid: string): Promise<CancelInfo> {
        const response = await this.cancelRaw({ aid: aid });
        return await response.value();
    }

    /**
     */
    async cancelDsRaw(requestParameters: CancelDsRequest): Promise<runtime.ApiResponse<CancelInfo>> {
        if (requestParameters.spId === null || requestParameters.spId === undefined) {
            throw new runtime.RequiredError('spId','Required parameter requestParameters.spId was null or undefined when calling cancelDs.');
        }

        const queryParameters: any = {};

        if (requestParameters.spId !== undefined) {
            queryParameters['spId'] = requestParameters.spId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/id/cancelDs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CancelInfoFromJSON(jsonValue));
    }

    /**
     */
    async cancelDs(spId: string): Promise<CancelInfo> {
        const response = await this.cancelDsRaw({ spId: spId });
        return await response.value();
    }

    /**
     */
    async failRaw(requestParameters: FailRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.aid === null || requestParameters.aid === undefined) {
            throw new runtime.RequiredError('aid','Required parameter requestParameters.aid was null or undefined when calling fail.');
        }

        const queryParameters: any = {};

        if (requestParameters.aid !== undefined) {
            queryParameters['aid'] = requestParameters.aid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/id/fail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async fail(aid: string): Promise<void> {
        await this.failRaw({ aid: aid });
    }

    /**
     */
    async finishRaw(requestParameters: FinishRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.aid === null || requestParameters.aid === undefined) {
            throw new runtime.RequiredError('aid','Required parameter requestParameters.aid was null or undefined when calling finish.');
        }

        const queryParameters: any = {};

        if (requestParameters.aid !== undefined) {
            queryParameters['aid'] = requestParameters.aid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/id/finish`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async finish(aid: string): Promise<void> {
        await this.finishRaw({ aid: aid });
    }

}
