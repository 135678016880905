import { Button, Grid } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Alert from '@material-ui/lab/Alert'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { AuthStatus } from '../saga/auth'
import { AppState } from '../saga/store'

export interface HsaIdInputProps {
    defaultHsaId: string | undefined
    handleLogin: (hsaId: string) => void
}

const HsaIdInput = (props: HsaIdInputProps) => {
    const status = useSelector<AppState, AuthStatus>((s) => s.auth.authStatus)
    const [alertMsg, setAlertMsg] = useState<string | undefined>(undefined)
    const intl = useIntl()
    const [hsaId, setHsaId] = useState<string | undefined>(props.defaultHsaId)
    const handleLogin = (id: string | undefined) => {
        if (id) {
            if (id.length >= 1) {
                props.handleLogin(id)
            } else {
                setAlertMsg('WM_NOT_CORRECT_HSA_ID')
            }
        } else {
            setAlertMsg('WM_NO_HSA_ID')
        }
    }
    return (
        <>
            {status === AuthStatus.NOT_STARTED && (
                <Grid className="Centred-Content" item xs={12}>
                    {alertMsg && (
                        <Alert severity="warning">
                            <FormattedMessage id={alertMsg} />
                        </Alert>
                    )}
                    <TextField
                        id="personalnumber"
                        placeholder={intl.formatMessage({
                            id: 'LA_HSA_ID_PLACEHOLDER',
                        })}
                        label={intl.formatMessage({ id: 'LA_HSA_ID' })}
                        value={hsaId}
                        onChange={(event) => {
                            setHsaId(event.target.value)
                        }}
                    />
                    <br />
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className="idp-btn idp-btn-hsa"
                        onClick={() => handleLogin(hsaId)}>
                        <FormattedMessage id={'LA_LOGIN'} />{' '}
                    </Button>
                </Grid>
            )}
        </>
    )
}
export default HsaIdInput
