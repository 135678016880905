import { useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import './App.css'
import ErrorBoundary from './components/ErrorBoundary'
import { getApiErrorReporter } from './errorReporter'
import { webApiClient } from './saga/api'
import { actions } from './saga/auth'
import { AppState } from './saga/store'
import { getPathParam } from './util/locationUtil'
import { Routes } from './view'

function App() {
    const dispatch = useDispatch()
    const searchParams = new URLSearchParams(window.location.search)
    const aid = searchParams.get('aid') || ''
    const spId = getPathParam(window.location.href, 'service_provider')!
    const lang = useSelector<AppState, string | undefined>((s) => s.auth.lang)

    useEffect(() => {
        dispatch(actions.lang.request())
    }, [])

    useEffect(() => {
        if (lang) {
            dispatch(actions.text.request({ lang: lang, spId: spId }))
        }
    }, [lang])

    const texts = useSelector((s: AppState) => s.auth.text)
    const errorReporter = getApiErrorReporter(webApiClient)
    return (
        <>
            {texts && lang && (
                <ErrorBoundary errorReporter={errorReporter}>
                    <IntlProvider messages={texts} locale={lang}>
                        <Routes aid={aid} lang={lang} spId={spId} />
                    </IntlProvider>
                </ErrorBoundary>
            )}
        </>
    )
}

export default App
