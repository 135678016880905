import { FormattedMessage, IntlProvider } from "react-intl";
import "./App.css";

function PageNotFound() {
  var searchParams = new URLSearchParams(window.location.search);
  var aid = searchParams.get("aid") || "";
  var lang = searchParams.get("lang") || navigator.languages[0].substr(0, 2);
  const textsEn = {
    header: "Page not found",
    information:
      "Something went wrong, troubleshooting reference : {reference}",
  };
  const textsSv = {
    header: "Sidan kunde inte hittas",
    information: "Något gick fel, referens vid felsökning: {reference}",
  };
  return (
    <IntlProvider messages={lang === "sv" ? textsSv : textsEn} locale={lang}>
      <div className="Center-Content-On-Page">
        <div
          style={{
            marginBottom: "0.25em",
            fontSize: "6rem",
            fontWeight: 300,
            lineHeight: 1.2,
          }}
        >
          <span className="idp-msg idp-header-info">
            <FormattedMessage id="header" />
          </span>
        </div>
        <p>
          <span className="idp-msg idp-msg-info">
            <FormattedMessage id="information" values={{ reference: aid }} />
          </span>
        </p>
      </div>
    </IntlProvider>
  );
}

export default PageNotFound;
