/**
 *
 * @param location
 * @param part
 */
export const getPathParam = (
    location: string,
    part: 'schema' | 'domain' | 'profile' | 'service_provider'
) => {
    // location is build up as: <schema>://<idp_domain>/<profile>/<service_provider>/
    const m =
        /(?<schema>[^/]+):\/\/(?<domain>[^/]+)\/web\/app\/(?<profile>[^/]+)\/(?<service_provider>[^/]+).+/.exec( // NOSONAR only used in tests
            location
        )
    return m && m.groups ? m.groups[part] : undefined
}

export const replaceLastPath = (pathName: string, newPath: string) =>
    pathName.split('/').slice(0, -2).join('/') + newPath
