/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FrejaInitParams,
    FrejaInitParamsFromJSON,
    FrejaInitParamsToJSON,
    StatusInfo,
    StatusInfoFromJSON,
    StatusInfoToJSON,
} from '../models';

export interface CancelFrejaSessionRequest {
    aid: string;
}

export interface FrejaAuthRequest {
    aid: string;
    id: string;
    frejaInitParams: FrejaInitParams;
}

export interface FrejaSignRequest {
    aid: string;
    id: string;
    lang: string;
    frejaInitParams: FrejaInitParams;
}

export interface GetFrejaQrValueRequest {
    aid: string;
}

export interface GetFrejaStatusRequest {
    aid: string;
}

/**
 * 
 */
export class FrejaApi extends runtime.BaseAPI {

    /**
     */
    async cancelFrejaSessionRaw(requestParameters: CancelFrejaSessionRequest): Promise<runtime.ApiResponse<StatusInfo>> {
        if (requestParameters.aid === null || requestParameters.aid === undefined) {
            throw new runtime.RequiredError('aid','Required parameter requestParameters.aid was null or undefined when calling cancelFrejaSession.');
        }

        const queryParameters: any = {};

        if (requestParameters.aid !== undefined) {
            queryParameters['aid'] = requestParameters.aid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/id/freja/cancel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusInfoFromJSON(jsonValue));
    }

    /**
     */
    async cancelFrejaSession(aid: string): Promise<StatusInfo> {
        const response = await this.cancelFrejaSessionRaw({ aid: aid });
        return await response.value();
    }

    /**
     */
    async frejaAuthRaw(requestParameters: FrejaAuthRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.aid === null || requestParameters.aid === undefined) {
            throw new runtime.RequiredError('aid','Required parameter requestParameters.aid was null or undefined when calling frejaAuth.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling frejaAuth.');
        }

        if (requestParameters.frejaInitParams === null || requestParameters.frejaInitParams === undefined) {
            throw new runtime.RequiredError('frejaInitParams','Required parameter requestParameters.frejaInitParams was null or undefined when calling frejaAuth.');
        }

        const queryParameters: any = {};

        if (requestParameters.aid !== undefined) {
            queryParameters['aid'] = requestParameters.aid;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/id/freja/auth`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrejaInitParamsToJSON(requestParameters.frejaInitParams),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async frejaAuth(aid: string, id: string, frejaInitParams: FrejaInitParams): Promise<string> {
        const response = await this.frejaAuthRaw({ aid: aid, id: id, frejaInitParams: frejaInitParams });
        return await response.value();
    }

    /**
     */
    async frejaSignRaw(requestParameters: FrejaSignRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.aid === null || requestParameters.aid === undefined) {
            throw new runtime.RequiredError('aid','Required parameter requestParameters.aid was null or undefined when calling frejaSign.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling frejaSign.');
        }

        if (requestParameters.lang === null || requestParameters.lang === undefined) {
            throw new runtime.RequiredError('lang','Required parameter requestParameters.lang was null or undefined when calling frejaSign.');
        }

        if (requestParameters.frejaInitParams === null || requestParameters.frejaInitParams === undefined) {
            throw new runtime.RequiredError('frejaInitParams','Required parameter requestParameters.frejaInitParams was null or undefined when calling frejaSign.');
        }

        const queryParameters: any = {};

        if (requestParameters.aid !== undefined) {
            queryParameters['aid'] = requestParameters.aid;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/id/freja/sign`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FrejaInitParamsToJSON(requestParameters.frejaInitParams),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async frejaSign(aid: string, id: string, lang: string, frejaInitParams: FrejaInitParams): Promise<string> {
        const response = await this.frejaSignRaw({ aid: aid, id: id, lang: lang, frejaInitParams: frejaInitParams });
        return await response.value();
    }

    /**
     */
    async getFrejaQrValueRaw(requestParameters: GetFrejaQrValueRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.aid === null || requestParameters.aid === undefined) {
            throw new runtime.RequiredError('aid','Required parameter requestParameters.aid was null or undefined when calling getFrejaQrValue.');
        }

        const queryParameters: any = {};

        if (requestParameters.aid !== undefined) {
            queryParameters['aid'] = requestParameters.aid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/id/freja/qr`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async getFrejaQrValue(aid: string): Promise<string> {
        const response = await this.getFrejaQrValueRaw({ aid: aid });
        return await response.value();
    }

    /**
     */
    async getFrejaStatusRaw(requestParameters: GetFrejaStatusRequest): Promise<runtime.ApiResponse<StatusInfo>> {
        if (requestParameters.aid === null || requestParameters.aid === undefined) {
            throw new runtime.RequiredError('aid','Required parameter requestParameters.aid was null or undefined when calling getFrejaStatus.');
        }

        const queryParameters: any = {};

        if (requestParameters.aid !== undefined) {
            queryParameters['aid'] = requestParameters.aid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/id/freja/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusInfoFromJSON(jsonValue));
    }

    /**
     */
    async getFrejaStatus(aid: string): Promise<StatusInfo> {
        const response = await this.getFrejaStatusRaw({ aid: aid });
        return await response.value();
    }

}
