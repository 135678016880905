import { CircularProgress, Grid } from '@material-ui/core'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import QRCode from 'react-qr-code'
import { useDispatch, useSelector } from 'react-redux'
import { StatusInfo } from '../generated/api'
import { AuthStatus } from '../saga/auth'
import { AppState } from '../saga/store'
import useInterval from '../util/useInterval'

export interface StatusProps {
    aid: string
    qr: undefined | 'STATIC' | 'ANIMATED'
    handleStatusMessage: (message: StatusInfo) => string
    hideQr?: (statusInfo: StatusInfo) => boolean
    statusAction: any
    qrAction: any
}

const Status = (props: StatusProps) => {
    const status = useSelector<AppState, AuthStatus>((s) => s.auth.authStatus)
    const statusRetries = useSelector<AppState, number>((s) => s.auth.statusRetries)
    const polling = useSelector<AppState, boolean>((s) => s.auth.polling)
    const statusInfo = useSelector<AppState, StatusInfo>((s) => s.auth.statusInfo)
    const qrValue = useSelector<AppState, string | undefined>((s) => s.auth.qrValue)

    const dispatch = useDispatch()
    useInterval(
        () => {
            if (status === AuthStatus.PENDING && !polling) {
                if (statusRetries <= 3) {
                    dispatch(props.statusAction.request({ aid: props.aid }))
                } else {
                    dispatch(
                        props.statusAction.failure({
                            name: '',
                            message: 'EM_STATUS_FETCH_FAILED',
                        })
                    )
                }
            }
        },
        status === AuthStatus.PENDING ? 2000 : null
    )
    const fetchQrValue = () => {
        if (props.qr && status === AuthStatus.PENDING) {
            dispatch(
                props.qrAction.request({
                    aid: props.aid
                })
            )
        }
    }
    useEffect(() => {
        fetchQrValue()
    }, [status, props.qr])
    useInterval(
        () => {
            fetchQrValue()
        },
        status === AuthStatus.PENDING && props.qr === 'ANIMATED' ? 1000 : null
    )
    return (
        <Grid className="Centred-Content" item xs={12}>
            {props.qr && (
                <>
                    {qrValue && (!props.hideQr || !props.hideQr(statusInfo)) ? (
                        <QRCode value={qrValue} />
                    ) : (
                        <CircularProgress />
                    )}
                    <br />
                </>
            )}
            {statusInfo.message && status !== AuthStatus.NOT_STARTED && (
                <span className="idp-msg idp-msg-info">
                    <FormattedMessage id={props.handleStatusMessage(statusInfo)} />
                </span>
            )}
        </Grid>
    )
}
export default Status
