/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusInfo
 */
export interface StatusInfo {
    /**
     * 
     * @type {string}
     * @memberof StatusInfo
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusInfo
     */
    substatus?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusInfo
     */
    message?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StatusInfo
     */
    sendAuthnFailed?: boolean;
}

export function StatusInfoFromJSON(json: any): StatusInfo {
    return StatusInfoFromJSONTyped(json, false);
}

export function StatusInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'substatus': !exists(json, 'substatus') ? undefined : json['substatus'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'sendAuthnFailed': !exists(json, 'sendAuthnFailed') ? undefined : json['sendAuthnFailed'],
    };
}

export function StatusInfoToJSON(value?: StatusInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'substatus': value.substatus,
        'message': value.message,
        'sendAuthnFailed': value.sendAuthnFailed,
    };
}


