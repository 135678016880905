/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AutoStartConfiguration,
    AutoStartConfigurationFromJSON,
    AutoStartConfigurationToJSON,
    InactiveInfo,
    InactiveInfoFromJSON,
    InactiveInfoToJSON,
    LoginMethodInfoObject,
    LoginMethodInfoObjectFromJSON,
    LoginMethodInfoObjectToJSON,
    UiError,
    UiErrorFromJSON,
    UiErrorToJSON,
    VidpInfo,
    VidpInfoFromJSON,
    VidpInfoToJSON,
} from '../models';

export interface GetAutoStartConfigurationRequest {
    userAgent: string;
}

export interface GetInactiveInfoRequest {
    spId: string;
}

export interface GetMethodsRequest {
    aid: string;
    lang: string;
    spId: string;
}

export interface GetStandardTextsRequest {
    lang: string;
}

export interface GetStatusRequest {
    aid: string;
    spId: string;
}

export interface GetTextRequest {
    spId: string;
    lang: string;
}

export interface GetVidpsRequest {
    spId: string;
    lang: string;
    fetchForCustomer: boolean;
    returnUrl: string;
    refId: string;
    returnIdParam?: string;
}

export interface LogErrorRequest {
    uiError: UiError;
}

/**
 * 
 */
export class WebApi extends runtime.BaseAPI {

    /**
     */
    async getAutoStartConfigurationRaw(requestParameters: GetAutoStartConfigurationRequest): Promise<runtime.ApiResponse<AutoStartConfiguration>> {
        if (requestParameters.userAgent === null || requestParameters.userAgent === undefined) {
            throw new runtime.RequiredError('userAgent','Required parameter requestParameters.userAgent was null or undefined when calling getAutoStartConfiguration.');
        }

        const queryParameters: any = {};

        if (requestParameters.userAgent !== undefined) {
            queryParameters['userAgent'] = requestParameters.userAgent;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/res/api/autostartconfiguration`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AutoStartConfigurationFromJSON(jsonValue));
    }

    /**
     */
    async getAutoStartConfiguration(userAgent: string): Promise<AutoStartConfiguration> {
        const response = await this.getAutoStartConfigurationRaw({ userAgent: userAgent });
        return await response.value();
    }

    /**
     */
    async getInactiveInfoRaw(requestParameters: GetInactiveInfoRequest): Promise<runtime.ApiResponse<InactiveInfo>> {
        if (requestParameters.spId === null || requestParameters.spId === undefined) {
            throw new runtime.RequiredError('spId','Required parameter requestParameters.spId was null or undefined when calling getInactiveInfo.');
        }

        const queryParameters: any = {};

        if (requestParameters.spId !== undefined) {
            queryParameters['spId'] = requestParameters.spId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/res/api/inactive`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InactiveInfoFromJSON(jsonValue));
    }

    /**
     */
    async getInactiveInfo(spId: string): Promise<InactiveInfo> {
        const response = await this.getInactiveInfoRaw({ spId: spId });
        return await response.value();
    }

    /**
     */
    async getMethodsRaw(requestParameters: GetMethodsRequest): Promise<runtime.ApiResponse<Array<LoginMethodInfoObject>>> {
        if (requestParameters.aid === null || requestParameters.aid === undefined) {
            throw new runtime.RequiredError('aid','Required parameter requestParameters.aid was null or undefined when calling getMethods.');
        }

        if (requestParameters.lang === null || requestParameters.lang === undefined) {
            throw new runtime.RequiredError('lang','Required parameter requestParameters.lang was null or undefined when calling getMethods.');
        }

        if (requestParameters.spId === null || requestParameters.spId === undefined) {
            throw new runtime.RequiredError('spId','Required parameter requestParameters.spId was null or undefined when calling getMethods.');
        }

        const queryParameters: any = {};

        if (requestParameters.aid !== undefined) {
            queryParameters['aid'] = requestParameters.aid;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        if (requestParameters.spId !== undefined) {
            queryParameters['spId'] = requestParameters.spId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/res/api/methods`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LoginMethodInfoObjectFromJSON));
    }

    /**
     */
    async getMethods(aid: string, lang: string, spId: string): Promise<Array<LoginMethodInfoObject>> {
        const response = await this.getMethodsRaw({ aid: aid, lang: lang, spId: spId });
        return await response.value();
    }

    /**
     */
    async getStandardTextsRaw(requestParameters: GetStandardTextsRequest): Promise<runtime.ApiResponse<{ [key: string]: string; }>> {
        if (requestParameters.lang === null || requestParameters.lang === undefined) {
            throw new runtime.RequiredError('lang','Required parameter requestParameters.lang was null or undefined when calling getStandardTexts.');
        }

        const queryParameters: any = {};

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/res/api/standardtexts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getStandardTexts(lang: string): Promise<{ [key: string]: string; }> {
        const response = await this.getStandardTextsRaw({ lang: lang });
        return await response.value();
    }

    /**
     */
    async getStatusRaw(requestParameters: GetStatusRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.aid === null || requestParameters.aid === undefined) {
            throw new runtime.RequiredError('aid','Required parameter requestParameters.aid was null or undefined when calling getStatus.');
        }

        if (requestParameters.spId === null || requestParameters.spId === undefined) {
            throw new runtime.RequiredError('spId','Required parameter requestParameters.spId was null or undefined when calling getStatus.');
        }

        const queryParameters: any = {};

        if (requestParameters.aid !== undefined) {
            queryParameters['aid'] = requestParameters.aid;
        }

        if (requestParameters.spId !== undefined) {
            queryParameters['spId'] = requestParameters.spId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/res/api/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async getStatus(aid: string, spId: string): Promise<string> {
        const response = await this.getStatusRaw({ aid: aid, spId: spId });
        return await response.value();
    }

    /**
     */
    async getSupportedLangsRaw(): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/res/api/supportedLangs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getSupportedLangs(): Promise<Array<string>> {
        const response = await this.getSupportedLangsRaw();
        return await response.value();
    }

    /**
     */
    async getTextRaw(requestParameters: GetTextRequest): Promise<runtime.ApiResponse<{ [key: string]: string; }>> {
        if (requestParameters.spId === null || requestParameters.spId === undefined) {
            throw new runtime.RequiredError('spId','Required parameter requestParameters.spId was null or undefined when calling getText.');
        }

        if (requestParameters.lang === null || requestParameters.lang === undefined) {
            throw new runtime.RequiredError('lang','Required parameter requestParameters.lang was null or undefined when calling getText.');
        }

        const queryParameters: any = {};

        if (requestParameters.spId !== undefined) {
            queryParameters['spId'] = requestParameters.spId;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/res/api/text`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getText(spId: string, lang: string): Promise<{ [key: string]: string; }> {
        const response = await this.getTextRaw({ spId: spId, lang: lang });
        return await response.value();
    }

    /**
     */
    async getVidpsRaw(requestParameters: GetVidpsRequest): Promise<runtime.ApiResponse<Array<VidpInfo>>> {
        if (requestParameters.spId === null || requestParameters.spId === undefined) {
            throw new runtime.RequiredError('spId','Required parameter requestParameters.spId was null or undefined when calling getVidps.');
        }

        if (requestParameters.lang === null || requestParameters.lang === undefined) {
            throw new runtime.RequiredError('lang','Required parameter requestParameters.lang was null or undefined when calling getVidps.');
        }

        if (requestParameters.fetchForCustomer === null || requestParameters.fetchForCustomer === undefined) {
            throw new runtime.RequiredError('fetchForCustomer','Required parameter requestParameters.fetchForCustomer was null or undefined when calling getVidps.');
        }

        if (requestParameters.returnUrl === null || requestParameters.returnUrl === undefined) {
            throw new runtime.RequiredError('returnUrl','Required parameter requestParameters.returnUrl was null or undefined when calling getVidps.');
        }

        if (requestParameters.refId === null || requestParameters.refId === undefined) {
            throw new runtime.RequiredError('refId','Required parameter requestParameters.refId was null or undefined when calling getVidps.');
        }

        const queryParameters: any = {};

        if (requestParameters.spId !== undefined) {
            queryParameters['spId'] = requestParameters.spId;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        if (requestParameters.fetchForCustomer !== undefined) {
            queryParameters['fetchForCustomer'] = requestParameters.fetchForCustomer;
        }

        if (requestParameters.returnUrl !== undefined) {
            queryParameters['returnUrl'] = requestParameters.returnUrl;
        }

        if (requestParameters.refId !== undefined) {
            queryParameters['refId'] = requestParameters.refId;
        }

        if (requestParameters.returnIdParam !== undefined) {
            queryParameters['returnIdParam'] = requestParameters.returnIdParam;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/res/api/vidps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VidpInfoFromJSON));
    }

    /**
     */
    async getVidps(spId: string, lang: string, fetchForCustomer: boolean, returnUrl: string, refId: string, returnIdParam?: string): Promise<Array<VidpInfo>> {
        const response = await this.getVidpsRaw({ spId: spId, lang: lang, fetchForCustomer: fetchForCustomer, returnUrl: returnUrl, refId: refId, returnIdParam: returnIdParam });
        return await response.value();
    }

    /**
     */
    async logErrorRaw(requestParameters: LogErrorRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uiError === null || requestParameters.uiError === undefined) {
            throw new runtime.RequiredError('uiError','Required parameter requestParameters.uiError was null or undefined when calling logError.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/res/api/log`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UiErrorToJSON(requestParameters.uiError),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async logError(uiError: UiError): Promise<void> {
        await this.logErrorRaw({ uiError: uiError });
    }

}
