import { Button, Grid } from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import React, { useCallback, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { actions } from '../../saga/auth'
import { AppState } from '../../saga/store'

export interface ErrorProps { }

const rejectSwedishTINCode: string = 'EM_REJECT_SWEDISH_TIN_OMITTED'
const waitingRoom1Text: string = 'WaitingRoom1'
const waitingRoom2Text: string = 'WaitingRoom2'
const waitingRoom3Text: string = 'WaitingRoom3'
const waitingRoom4Text: string = 'WaitingRoom4'

export default (props: ErrorProps) => {
    var urlParams = new URLSearchParams(window.location.search)

    const refId = urlParams.get('refId') || urlParams.get('aid') || ''
    const errorCode = urlParams.get('errorCode')
    const instant = urlParams.get('instant') || Date.now()
    const redirect = urlParams.get('redirect')

    const intl = useIntl()
    const isWaitingRoom = errorCode === rejectSwedishTINCode

    // authErrorMessage has precedences over props.errorCode
    const authErrorMessage = useSelector((s: AppState) => s.auth.errorMsg)

    var formattedInstant = useMemo(() => {
        var date = new Date(+instant)
        return intl.formatDate(date) + ' ' + intl.formatTime(date)
    }, [intl, instant])

    const getErrorView = () => {

        const message =
            errorCode || authErrorMessage
                ? intl.formatMessage({ id: authErrorMessage || errorCode! })
                : ''

        return (
            <>
                <Grid className="Centred-Content" item xs={12}>
                    <ErrorOutlineIcon className="idp-error-icon" />
                    <br />
                    <span className="idp-msg idp-msg-error">
                        <FormattedMessage
                            id={'TEMPLATE_ERROR'}
                            values={{
                                message: `${message}`,
                                reference: refId,
                                timeStamp: formattedInstant,
                                lineBreak: <br />,
                                title: errorCode,
                                b: (...chunks: any[]) => <b>{chunks}</b>,
                                i: (...chunks: any[]) => <i>{chunks}</i>,
                            }}
                        />
                    </span>
                </Grid>
                <Grid className="Filler" item xs={12}></Grid>
                <div className="Bottom-Content">
                    {redirect && (
                        <Button fullWidth className="idp-btn idp-btn-back-to-login idp-btn-back-to-login-error" variant="outlined" onClick={() => handleRedirect()}>
                            <FormattedMessage id="LA_BACK_TO_LOGINPAGE" />
                        </Button>
                    )}
                    {authErrorMessage && (
                        <Button fullWidth className="idp-btn idp-btn-back idp-btn-back-error" variant="outlined" onClick={() => handleBack()}>
                            <FormattedMessage id="LA_BACK" />
                        </Button>
                    )}
                </div>
            </>
        )
    }

    const getWaitingRoom = () => {

        const waitingRoom1 = intl.formatMessage({ id: waitingRoom1Text })
        const waitingRoom2 = intl.formatMessage({ id: waitingRoom2Text })
        const waitingRoom3 = intl.formatMessage({ id: waitingRoom3Text })
        const waitingRoom4 = intl.formatMessage({ id: waitingRoom4Text })

        return (
            <>
                <Grid className="Centred-Content" item xs={12}>
                    <ErrorOutlineIcon />
                    <br />
                    <FormattedMessage
                        id={'TEMPLATE_WAITING_ROOM'}
                        values={{
                            waitingRoom1: waitingRoom1,
                            waitingRoom2: waitingRoom2,
                            waitingRoom3: waitingRoom3,
                            waitingRoom4: waitingRoom4,
                            ul: (chunks: any[]) => <ul>{chunks}</ul>,
                            li: (chunks: any[]) => <li>{chunks}</li>
                        }}
                    />
                </Grid>
                <Grid className="Filler" item xs={12}></Grid>
                <div className="Bottom-Content">
                    {redirect && (
                        <Button className="idp-btn idp-btn-back-to-login idp-btn-back-to-login-waitingroom" fullWidth variant="outlined" onClick={() => handleRedirect()}>
                            <FormattedMessage id="LA_BACK_TO_LOGINPAGE" />
                        </Button>
                    )}
                </div>
            </>
        )
    }

    const handleRedirect = useCallback(() => {
        window.location.assign(redirect!)
    }, [])

    const history = useHistory()
    const dispatch = useDispatch()
    const handleBack = useCallback(() => {
        dispatch(actions.resetAfterError())
        history.push('/')
    }, [dispatch, history])

    return isWaitingRoom ? getWaitingRoom() : getErrorView()
}
