import { useEffect } from "react";
import { useTimer } from "../util/useTimer";

export const UserInactiveTimer = (props: {
  delay: number;
  callback: () => void;
}) => {
  const [reset] = useTimer(props.delay, props.callback);

  useEffect(() => {
    document.addEventListener("mouseup", reset);
    document.addEventListener("keydown", reset);
    return () => {
      document.removeEventListener("mouseup", reset);
      document.removeEventListener("keydown", reset);
    };
  }, [reset]);

  return null;
};
