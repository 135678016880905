/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UiError
 */
export interface UiError {
    /**
     * 
     * @type {string}
     * @memberof UiError
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UiError
     */
    refId?: string;
    /**
     * 
     * @type {string}
     * @memberof UiError
     */
    message?: string;
}

export function UiErrorFromJSON(json: any): UiError {
    return UiErrorFromJSONTyped(json, false);
}

export function UiErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): UiError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'refId': !exists(json, 'refId') ? undefined : json['refId'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function UiErrorToJSON(value?: UiError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'refId': value.refId,
        'message': value.message,
    };
}


