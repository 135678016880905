/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InactiveInfo
 */
export interface InactiveInfo {
    /**
     * 
     * @type {string}
     * @memberof InactiveInfo
     */
    redirectUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof InactiveInfo
     */
    timeoutMs?: number;
}

export function InactiveInfoFromJSON(json: any): InactiveInfo {
    return InactiveInfoFromJSONTyped(json, false);
}

export function InactiveInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InactiveInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'redirectUrl': !exists(json, 'redirectUrl') ? undefined : json['redirectUrl'],
        'timeoutMs': !exists(json, 'timeoutMs') ? undefined : json['timeoutMs'],
    };
}

export function InactiveInfoToJSON(value?: InactiveInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'redirectUrl': value.redirectUrl,
        'timeoutMs': value.timeoutMs,
    };
}


