import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from '../saga/auth'
import { AppState } from '../saga/store'
import { UserInactiveTimer } from './UserInactiveTimer'

export const InactiveComponent = (props: { children: React.ReactNode; spId: string }) => {
    const dispatch = useDispatch()
    const inactiveTimeoutMs = useSelector<AppState, number | undefined>(
        (s) => s.auth.inactiveTimeoutMs
    )

    useEffect(() => {
        if (props.spId) {
            dispatch(actions.loadInactiveData.request(props.spId))
        }
    }, [props.spId])

    return (
        <>
            {inactiveTimeoutMs && (
                <UserInactiveTimer
                    delay={inactiveTimeoutMs}
                    callback={() => dispatch(actions.userInactive())}
                />
            )}
            {props.children}
        </>
    )
}
