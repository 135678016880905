/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CancelInfo
 */
export interface CancelInfo {
    /**
     * 
     * @type {string}
     * @memberof CancelInfo
     */
    redirectURL?: string;
}

export function CancelInfoFromJSON(json: any): CancelInfo {
    return CancelInfoFromJSONTyped(json, false);
}

export function CancelInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancelInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'redirectURL': !exists(json, 'redirectURL') ? undefined : json['redirectURL'],
    };
}

export function CancelInfoToJSON(value?: CancelInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'redirectURL': value.redirectURL,
    };
}


