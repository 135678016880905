/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrejaOrgIdInitParams
 */
export interface FrejaOrgIdInitParams {
    /**
     * 
     * @type {string}
     * @memberof FrejaOrgIdInitParams
     */
    userInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof FrejaOrgIdInitParams
     */
    userInfoType?: FrejaOrgIdInitParamsUserInfoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FrejaOrgIdInitParams
     */
    orgIdIssuer?: string;
}

/**
* @export
* @enum {string}
*/
export enum FrejaOrgIdInitParamsUserInfoTypeEnum {
    Email = 'EMAIL',
    Ssn = 'SSN',
    Phone = 'PHONE',
    Inferred = 'INFERRED',
    OrgId = 'ORG_ID'
}

export function FrejaOrgIdInitParamsFromJSON(json: any): FrejaOrgIdInitParams {
    return FrejaOrgIdInitParamsFromJSONTyped(json, false);
}

export function FrejaOrgIdInitParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrejaOrgIdInitParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userInfo': !exists(json, 'userInfo') ? undefined : json['userInfo'],
        'userInfoType': !exists(json, 'userInfoType') ? undefined : json['userInfoType'],
        'orgIdIssuer': !exists(json, 'orgIdIssuer') ? undefined : json['orgIdIssuer'],
    };
}

export function FrejaOrgIdInitParamsToJSON(value?: FrejaOrgIdInitParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userInfo': value.userInfo,
        'userInfoType': value.userInfoType,
        'orgIdIssuer': value.orgIdIssuer,
    };
}


