import { isDesktop, isIOS, isSafari } from 'react-device-detect'
import { AutoStartConfiguration, LoginMethodInfoObject, StatusInfo } from './../generated/api'
import { bankIdClient } from './../saga/api'

export const ERROR_RFA_CODES = [
    'RFA2',
    'RFA3',
    'RFA4',
    'RFA5',
    'RFA6',
    'RFA8',
    'RFA16',
    'RFA17A',
    'RFA17B',
    'RFA22',
]

export function hideQr(statusInfo: StatusInfo) {
    if (!statusInfo.message) {
        return false
    }
    return statusInfo.message === 'RFA9'
}

export function getStartBankIdAppUrl(token: string | undefined, autoStartConfiguration : AutoStartConfiguration | undefined) {
    if (isIOS && isSafari) {
        // Below are some specifics about why Safari is handled differently from other browsers. 
        // The return URL needs to be slightly different for Safari as the URL back to core-web cannot end with a trailing slash. This should "override" any other AutoStart configurations defined.
        // No specific return URL scheme is used for Safari. Only the URL back to core-web (with trailing slash removed) is required.
        // With the "x-safari-https:" return URL scheme it's only possible to open up a new page in Safari. Opening Safari back up to the previous page does not seem to be possible.
        // If the users default browser is set to anything other than Safari, and Safari is used, redirecting back to Safari to the same page is not possible. 
        let redirectUrl = window.location.href
        if (redirectUrl.endsWith('/')) {
            redirectUrl = redirectUrl.slice(0, -1) // removes trailing slash. seems to not cause a full page reload on safari, not sure why.
        }
        // Start URL should be bankid:// (Should always work)
        let startUrl = 'bankid://'
        // Construct the BankID app URL
        return startUrl +
            '/?autostarttoken=' + token +
            '&redirect=' + encodeURIComponent(redirectUrl)
    }
    
    //Checks if autoStartConfiguration is undefined, if it is, use standard behaviour (which is using bankid:// with no redirect).
    if(autoStartConfiguration === null || autoStartConfiguration === undefined){
        return 'bankid:///?autostarttoken=' + token + '&redirect=null';
    } else {
        // Any other browser. Use AutoStart configuration values.
        const baseRedirectUrl = autoStartConfiguration?.returnUrl || "";
        const redirectUrl = encodeURIComponent(baseRedirectUrl) + (autoStartConfiguration.appendBack ? encodeURIComponent(window.location.href) : '')  
        return autoStartConfiguration.startUrl + 
            '/?autostarttoken=' + token + 
            '&redirect=' + redirectUrl
     }
 }    

export function getBankIdPromise(
    aid: string,
    loginMethod: LoginMethodInfoObject,
    lang: string
): Promise<string> {
    return loginMethod.signMesssage
        ? bankIdClient.bankIdSign(aid, loginMethod.id!, lang)
        : bankIdClient.bankIdAuth(aid, loginMethod.id!, lang)
}
//Function to retrieve a autoStartToken from core.
export function getBankidAutoStartToken(
    aid: string
): Promise<string> {
    return bankIdClient.getAutoStartToken(aid)
}

export function addRfaSuffix(message: string, qr: boolean) {
    if ('RFA17' === message) {
        message = qr ? 'RFA17B' : 'RFA17A'
    }
    if ('RFA15' === (message)) {
        message = isDesktop ? message + 'A' : message + 'B'
    }
    return message
}
