/* eslint-disable import/no-anonymous-default-export */

import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import InfoMessage from '../../components/InfoMessage'
import PersonalNumberInput from '../../components/PersonalNumberInput'
import StartFrejaAppButton from '../../components/StartIdAppButton'
import Status from '../../components/Status'
import ToggleQrPnrButton from '../../components/ToggleQrPnrButton'
import { AutoStartConfiguration, LoginMethodInfoObject, StatusInfo } from '../../generated/api'
import { frejaClient, webApiClient } from '../../saga/api'
import { actions, AuthStatus } from '../../saga/auth'
import { AppState } from '../../saga/store'
import { replaceLastPath } from '../../util/locationUtil'
import {
    getCancelOrError,
    getFrejaPromise,
    getStartFrejaAppUrl,
    hideQr,
    isErrorStatus,
} from '../../util/frejaUtils'
import { getUA, isAndroid, isChrome, isIOS } from 'react-device-detect'
import { CircularProgress } from '@material-ui/core'

interface LoginFrejaProps {
    aid: string
    spId: string
    pnrMode: boolean
    qrMode: boolean
    lang: string
    loginMethodId: string
    allowHandleBack: boolean
}

export default (props: LoginFrejaProps) => {
    const { aid, lang, pnrMode, qrMode, allowHandleBack } = { ...props }
    const dispatch = useDispatch()
    const history = useHistory()
    const [autostarttoken, setAutoStartToken] = useState<string | undefined>(undefined)
    const [autoStartConfig, setAutoStartConfig] = useState<AutoStartConfiguration | undefined>(undefined)
    const selectedMethod = useSelector<AppState, LoginMethodInfoObject | undefined>(
        (s) => s.auth.selectedMethod
    )
    //Boolean is used to know if this loginmethod was the only one available.
    const loneMethod = useSelector<AppState, boolean | undefined>(
        (s) => s.auth.methods.length === 1 ? true : false
    )

    useEffect(() => {
        if (selectedMethod) {
            if (!pnrMode || selectedMethod?.providedIdentification) {
                handleLogin(selectedMethod.providedIdentification)
            }
        }
    }, [pnrMode, selectedMethod])

    const config: any = selectedMethod?.parameters
    const sameDevice = useMemo(() => config?.device === 'true', [config?.device])
    /* Boolean if browser should try to start Freja automatically.
    It needs to check if this is the only method active in vIdp, because
    if it's Chrome on Android, an autostart wouldn't return back to the correct
    page in core-web, but to the page that last had an interaction from the user,
    so it's disabled by default. Otherwise, it will check the config.*/
    const tryAutostart = useMemo(() => {return (loneMethod && isChrome && isAndroid) ? false : config?.tryAutostart === 'true'}, [config?.tryAutostart])

    const handleLogin = (personalNumber: string | undefined) => {
        if (!selectedMethod) {
            return
        }
        dispatch(actions.setStatus(AuthStatus.INITIATED))

        const frejaPromise: Promise<string> = getFrejaPromise(
            aid,
            selectedMethod,
            personalNumber,
            lang
        )
        //Check if we (the end-user browser) are running on iOS due to iOS needing special behaviour for links to and from Freja app. AutoStartConfiguration is only needed for iOS.
        //Also checks if the login method is of type "sameDevice" as code below is only needed if ID provider and client accessing eid-verify is on same device.
        if(isIOS && sameDevice){
            const autoStartConfigPromise: Promise<AutoStartConfiguration> = webApiClient.getAutoStartConfiguration(getUA);
            Promise.all([frejaPromise, autoStartConfigPromise]).then(responses => {
                setAutoStartToken(responses[0])
                dispatch(actions.setStatus(AuthStatus.PENDING))
                setAutoStartConfig(responses[1])
                if (tryAutostart) {
                    window.location.href = getStartFrejaAppUrl(responses[0], responses[1])
                }
            })
            .catch(() => dispatch(actions.setStatus(AuthStatus.ERROR)))
        }
        else {
            frejaPromise
            .then((response) => {
                setAutoStartToken(response)
                dispatch(actions.setStatus(AuthStatus.PENDING))
                if (sameDevice && tryAutostart) {
                    window.location.href = getStartFrejaAppUrl(response, undefined)
                }
            })
            .catch(() => dispatch(actions.setStatus(AuthStatus.ERROR))) 
        }
    }

    const toggleQrPnr = () => {
        const doToggle = () => {
            dispatch(actions.setStatus(AuthStatus.NOT_STARTED))
            dispatch(actions.setStatusInfo({}))
            const newPath = !qrMode ? '/qr/' : '/pn/'
            history.push(replaceLastPath(history.location.pathname, newPath))
        }
        if (qrMode) {
            frejaClient
                .cancelFrejaSession(aid)
                .then(() => {
                    doToggle()
                })
                .catch(() => dispatch(actions.setStatus(AuthStatus.ERROR)))
        } else {
            doToggle()
        }
    }

    const handleStatusMessage = (statusInfo: StatusInfo) => {
        if (isErrorStatus(statusInfo)) {
            dispatch(actions.setErrorMsg(statusInfo.message))
            dispatch(actions.setStatus(getCancelOrError(statusInfo)))
        }
        return statusInfo.message ? statusInfo.message : ''
    }

    return (
        <>
            {pnrMode && allowHandleBack && (
                <PersonalNumberInput
                    defaultPersonalNumber={selectedMethod?.providedIdentification}
                    handleLogin={handleLogin}
                />
            )}
            {allowHandleBack && <InfoMessage
                qr={qrMode}
                sameDevice={sameDevice}
                loggedInMessage="IN_FREJA_LOGGED_IN"
                scanQrMessage="IN_FREJA_SCAN_QR"
                startAppMessage=""
                hideQrMessage={hideQr}
                textClassName="idp-header idp-header-info"
            />}
            {allowHandleBack && <Status
                aid={props.aid}
                qr={qrMode ? 'STATIC' : undefined}
                handleStatusMessage={handleStatusMessage}
                statusAction={actions.frejaStatus}
                qrAction={actions.frejaQr}
                hideQr={hideQr}
            />}
            {allowHandleBack && <StartFrejaAppButton
                sameDevice={sameDevice}
                getStartIdAppUrl={getStartFrejaAppUrl}
                autostarttoken={autostarttoken}
                autoStartConfig={autoStartConfig}
                tryAutostart={tryAutostart}
                buttonLabel="IN_FREJA_START_THE_APP"
                type="freja"
            />}
            {config.loginMethod === 'qr_or_pnr' && allowHandleBack &&(
                <ToggleQrPnrButton qr={qrMode} toggleQrPnr={toggleQrPnr} />
            )}
            {!allowHandleBack && <CircularProgress />}
        </>
    
    )
}
