import { createHashHistory } from "history";
import ErrorInfo from "redux-saga"
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { spawn } from "redux-saga/effects";
import { AuthState, reducer as authReducer, saga as authSaga } from "./auth";
import { getApiErrorReporter } from "../errorReporter";
import { webApiClient } from './api'

export const history = createHashHistory();

export interface AppState {
  auth: AuthState;
}

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
  name: "LEGO - Web",
});

const reducer = combineReducers({
  auth: authReducer,
});

const rootSaga = function* () {
  yield spawn(authSaga, history);
};

const errorReporter = getApiErrorReporter(webApiClient)

const sagaMiddleware = createSagaMiddleware({
  onError: (error: Error, errorInfo: any) => {
    errorReporter('sagaMiddleware', error)
  }
});

export const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);
