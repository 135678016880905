/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VidpIcon,
    VidpIconFromJSON,
    VidpIconFromJSONTyped,
    VidpIconToJSON,
} from './';

/**
 * 
 * @export
 * @interface VidpInfo
 */
export interface VidpInfo {
    /**
     * 
     * @type {string}
     * @memberof VidpInfo
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof VidpInfo
     */
    redirectUrl: string;
    /**
     * 
     * @type {VidpIcon}
     * @memberof VidpInfo
     */
    icon?: VidpIcon;
}

export function VidpInfoFromJSON(json: any): VidpInfo {
    return VidpInfoFromJSONTyped(json, false);
}

export function VidpInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VidpInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'redirectUrl': json['redirectUrl'],
        'icon': !exists(json, 'icon') ? undefined : VidpIconFromJSON(json['icon']),
    };
}

export function VidpInfoToJSON(value?: VidpInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'redirectUrl': value.redirectUrl,
        'icon': VidpIconToJSON(value.icon),
    };
}


