import { BankidApi, IdControllerApi, WebApi } from '../generated/api'
import { FrejaorgidApi } from '../generated/api'
import { Configuration } from '../generated/api/runtime'
import { FrejaApi } from '../generated/api'

export const baseUrl = process.env.NODE_ENV !== 'production' ? 'https://localhost.cgi.com:8080' : ''

const configuration = new Configuration({
    basePath: baseUrl,
    middleware: [],
})

export const webApiClient = new WebApi(configuration)
export const idClient = new IdControllerApi(configuration)
export const bankIdClient = new BankidApi(configuration)
export const frejaClient = new FrejaApi(configuration)
export const frejaOrgIdClient = new FrejaorgidApi(configuration)