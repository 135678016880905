import { Button, Grid } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Alert from '@material-ui/lab/Alert'
import Personnummer from 'personnummer'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { AuthStatus } from '../saga/auth'
import { AppState } from '../saga/store'

export interface PersonalNumberInputProps {
    defaultPersonalNumber: string | undefined
    handleLogin: (personalNumber: string) => void
}

const isValidPersonalNumber = (personalNumber: string) => {
    return Personnummer.valid(personalNumber)
}

const PersonalNumberInput = (props: PersonalNumberInputProps) => {
    const status = useSelector<AppState, AuthStatus>((s) => s.auth.authStatus)
    const [alertMsg, setAlertMsg] = useState<string | undefined>(undefined)
    const intl = useIntl()
    const [personalNumber, setPersonalnumber] = useState<string | undefined>(
        props.defaultPersonalNumber
    )
    //console.log('personalNumber', personalNumber, props.defaultPersonalNumber)
    const handleLogin = (pnr: string | undefined) => {
        if (pnr) {
            if (isValidPersonalNumber(pnr) && pnr.length >= 12) {
                props.handleLogin(pnr)
            } else {
                setAlertMsg('WM_NOT_CORRECT_PERSONALNUMBER')
            }
        } else {
            setAlertMsg('WM_NO_PERSONALNUMBER')
        }
    }
    return (
        <>
            {status === AuthStatus.NOT_STARTED && (
                <Grid className="Centred-Content" item xs={12}>
                    {alertMsg && (
                        <Alert severity="warning">
                            <FormattedMessage id={alertMsg} />
                        </Alert>
                    )}
                    <TextField
                        id="personalnumber"
                        placeholder={intl.formatMessage({
                            id: 'LA_PERSONAL_NUMBER_PLACEHOLDER',
                        })}
                        label={intl.formatMessage({ id: 'LA_PERSONAL_NUMBER' })}
                        value={personalNumber}
                        onChange={(event) => {
                            setPersonalnumber(event.target.value)
                        }}
                    />
                    <br />
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className="idp-btn idp-btn-pnr"
                        onClick={() => handleLogin(personalNumber)}>
                        <FormattedMessage id={'LA_LOGIN'} />{' '}
                    </Button>
                </Grid>
            )}
        </>
    )
}
export default PersonalNumberInput
