import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router'
import { useHistory } from 'react-router-dom'
import { AuthStatus } from '../saga/auth'
import { AppState } from '../saga/store'
import BankId from './BankId'
import ChooseVidp from './ds'
import Error from './Error'
import Freja from './Freja'
import Login from './Login'
import Nias from './Nias'
import FrejaOrgId from './FrejaOrgId'

export const Routes = (props: { aid: string; lang: string; spId: string }) => {
    const { aid, lang, spId } = { ...props }
    const history = useHistory()
    const status = useSelector<AppState, AuthStatus>((s) => s.auth.authStatus)

    useEffect(() => {
        if ([AuthStatus.ERROR, AuthStatus.CANCELLED].includes(status)) {
            history.push('/error')
        }
    }, [status])
    return (
        <Switch>
            <Route exact path="/" component={() => <Login lang={lang} aid={aid} spId={spId} />} />
            <Route
                exact
                path="/bankid/:id/"
                render={(p) => (
                    <BankId
                        lang={lang}
                        aid={aid}
                        spId={spId}
                        loginMethodId={p.match.params.id}
                        pnrMode={false}
                        qrMode={false}
                    />
                )}
            />
            <Route
                exact
                path="/bankid/:id/pn/"
                render={(p) => (
                    <BankId
                        lang={lang}
                        aid={aid}
                        spId={spId}
                        loginMethodId={p.match.params.id}
                        pnrMode={true}
                        qrMode={false}
                    />
                )}
            />
            <Route
                exact
                path="/bankid/:id/qr/"
                render={(p) => (
                    <BankId
                        lang={lang}
                        aid={aid}
                        spId={spId}
                        loginMethodId={p.match.params.id}
                        pnrMode={false}
                        qrMode={true}
                    />
                )}
            />
            <Route
                exact
                path="/freja/:id/"
                render={(p) => (
                    <Freja
                        lang={lang}
                        aid={aid}
                        spId={spId}
                        loginMethodId={p.match.params.id}
                        pnrMode={false}
                        qrMode={false}
                    />
                )}
            />
            <Route
                exact
                path="/freja/:id/pn/"
                render={(p) => (
                    <Freja
                        lang={lang}
                        aid={aid}
                        spId={spId}
                        loginMethodId={p.match.params.id}
                        pnrMode={true}
                        qrMode={false}
                    />
                )}
            />
            <Route
                exact
                path="/freja/:id/qr/"
                render={(p) => (
                    <Freja
                        lang={lang}
                        aid={aid}
                        spId={spId}
                        loginMethodId={p.match.params.id}
                        pnrMode={false}
                        qrMode={true}
                    />
                )}
            />
            <Route
                exact
                path="/frejaorgid/:id/"
                render={(p) => (
                    <FrejaOrgId
                        lang={lang}
                        aid={aid}
                        spId={spId}
                        loginMethodId={p.match.params.id}
                        pnrMode={false}
                        qrMode={false}
                    />
                )}
            />
            <Route
                exact
                path="/frejaorgid/:id/pn/"
                render={(p) => (
                    <FrejaOrgId
                        lang={lang}
                        aid={aid}
                        spId={spId}
                        loginMethodId={p.match.params.id}
                        pnrMode={true}
                        qrMode={false}
                    />
                )}
            />
            <Route
                exact
                path="/frejaorgid/:id/qr/"
                render={(p) => (
                    <FrejaOrgId
                        lang={lang}
                        aid={aid}
                        spId={spId}
                        loginMethodId={p.match.params.id}
                        pnrMode={false}
                        qrMode={true}
                    />
                )}
            />
            <Route
                exact
                path="/nias/:id/"
                render={(p) => (
                    <Nias
                        lang={lang}
                        aid={aid}
                        spId={spId}
                        loginMethodId={p.match.params.id}
                        pnrMode={false}
                        qrMode={false}
                    />
                )}
            />
            <Route
                exact
                path="/nias/:id/pn/"
                render={(p) => (
                    <Nias
                        lang={lang}
                        aid={aid}
                        spId={spId}
                        loginMethodId={p.match.params.id}
                        pnrMode={true}
                        qrMode={false}
                    />
                )}
            />
            <Route
                exact
                path="/nias/:id/qr/"
                render={(p) => (
                    <Nias
                        lang={lang}
                        aid={aid}
                        spId={spId}
                        loginMethodId={p.match.params.id}
                        pnrMode={false}
                        qrMode={true}
                    />
                )}
            />
            <Route path="/ds/" render={() => <ChooseVidp lang={lang} spId={spId} />} />
            <Route path="/error" component={Error} />
            <Route render={() => <div>Loginmethod not implemented yet</div>} />
        </Switch>
    )
}
