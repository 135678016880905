import { Button, Grid } from '@material-ui/core'
import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { actions, AuthStatus } from '../saga/auth'
import { AppState } from '../saga/store'
import { InactiveComponent } from './InactiveComponent'

export interface LoginProps {
    lang: string
    aid: string
    spId: string
}

export interface BaseLoginComponentProps extends LoginProps {
    icon?: string
    renderBack: boolean
    handleBack?: () => void
    handleCancel?: () => void
    children: React.ReactNode
}
export const BaseLoginComponent = (props: BaseLoginComponentProps) => {
    const dispatch = useDispatch()
    const { renderBack, handleBack, handleCancel, aid, spId } = { ...props }
    const status = useSelector<AppState, AuthStatus>((s) => s.auth.authStatus)
    const renderCancel = useMemo(() => status !== AuthStatus.COMPLETED, [status])
    const onCancel = () => {
        dispatch(actions.cancel.request({ aid }))
        if (handleCancel) {
            handleCancel()
        }
    }

    const onBack = () => {
        dispatch(actions.setStatus(AuthStatus.NOT_STARTED))
        if (handleBack) {
            handleBack()
        }
    }

    return (
        <InactiveComponent spId={spId}>
            <Grid className="Centred-Content" item xs={12}>
                <Grid style={{ height: '80px' }} className="Centred-Content" item xs={12}>
                    {props.icon && <img style={{ height: '80px' }} src={props.icon} />}
                </Grid>
                {props.children}
            </Grid>
            <Grid className="Filler" item xs={12}></Grid>
            <div className="Bottom-Content">
                {renderBack !== false && (
                    <Button fullWidth variant="outlined" className="idp-btn idp-btn-back idp-btn-back-login" onClick={onBack}>
                        <FormattedMessage id="LA_BACK" />
                    </Button>
                )}
                {renderCancel && (
                    <Button fullWidth variant="outlined" className="idp-btn idp-btn-cancel idp-btn-cancel-login" onClick={onCancel}>
                        <FormattedMessage id="LA_CANCEL" />
                    </Button>
                )}
            </div>
        </InactiveComponent>
    )
}
