import { Button, Grid } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

export interface ToggleQrPnrButtonProps {
    qr: boolean
    toggleQrPnr: () => void
}

const ToggleQrPnrButton = (props: ToggleQrPnrButtonProps) => {
    return (
        <>
            <Grid className="Centred-Content" item xs={12}>
                <br />
                <Button fullWidth variant="outlined" className="idp-btn idp-btn-toggle-pnr-qr" onClick={() => props.toggleQrPnr()}>
                    <FormattedMessage id={props.qr ? 'LA_LOGIN_PERSONAL_NUMBER' : 'LA_LOGIN_QR'} />
                </Button>
            </Grid>
        </>
    )
}
export default ToggleQrPnrButton
