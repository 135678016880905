import React from "react"
import { ErrorReporter } from '../errorReporter'

type Props = {
    errorReporter: ErrorReporter
}

type State = {
    hasError: boolean
    refId?: string
}

const generateQuickGuid = () => {
    return Math.random().toString(20).substring(2, 10) +  // NOSONAR Not security-sensitive
        Math.random().toString(20).substring(2, 10);  // NOSONAR Not security-sensitive
}

export default class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = { hasError: false, refId: undefined }
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true, refId: generateQuickGuid() };
    }

    componentDidCatch(error: Error, errorInfo: any) {
        this.props.errorReporter('errorBoundary', error, this.state.refId)
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong. ({this.state.refId})</h1>;
        }

        return this.props.children;
    }
}