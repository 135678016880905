import { History } from 'history'
import { isMobile } from 'react-device-detect'
import { put, select, takeLatest } from 'redux-saga/effects'
import { ActionType, createAction, createAsyncAction, createReducer } from 'typesafe-actions'
import { getApiErrorReporter } from '../errorReporter'
import { StatusInfo } from '../generated/api'
import { CancelInfo, InactiveInfo, LoginMethodInfoObject, VidpInfo } from '../generated/api/models'
import { bankIdClient, baseUrl, frejaClient, idClient, webApiClient, frejaOrgIdClient } from './api'
import { AppState } from './store'

export interface AuthState {
    text?: { [key: string]: string }
    standardtexts?: { [key: string]: string }
    vidps: VidpInfo[]
    methods: LoginMethodInfoObject[]
    selectedMethod?: LoginMethodInfoObject
    errorMsg?: string
    authStatus: AuthStatus
    statusInfo: StatusInfo
    polling: boolean
    statusRetries: number
    inactiveTimeoutMs?: number
    inactiveRedirectUrl?: string
    qrValue?: string
    lang?: string
}
export interface MethodsRequest {
    aid: string
    lang: string
    spId: string
}

export interface TextRequest {
    spId: string
    lang: string
}
export interface GenericRequest {
    aid: string
}

export enum AuthStatus {
    NOT_STARTED,
    INITIATED,
    PENDING,
    CANCELLED,
    ERROR,
    COMPLETED,
}

export const actions = {
    userInactive: createAction('@auth/userinactive')<undefined>(),
    loadInactiveData: createAsyncAction(
        '@auth/userinactive/request',
        '@auth/userinactive/success',
        '@auth/userinactive/failure',
        '@auth/userinactive/cancel'
    )<string, InactiveInfo, Error, undefined>(),
    setStatus: createAction('@auth/status/set')<AuthStatus>(),
    setStatusInfo: createAction('@auth/statusInfo/set')<StatusInfo>(),
    setPolling: createAction('@auth/polling/set')<boolean>(),
    resetAfterError: createAction('@auth/resetAfterError')(),
    incrementStatusRetries: createAction('@auth/statusRetires/increment')(),
    resetStatusRetries: createAction('@auth/statusRetires/reset')(),
    lang: createAsyncAction(
        '@auth/lang/request',
        '@auth/lang/success',
        '@auth/lang/failure',
        '@auth/lang/cancel'
    )<undefined, string, Error, undefined>(),
    text: createAsyncAction(
        '@auth/text/request',
        '@auth/text/success',
        '@auth/text/failure',
        '@auth/text/cancel'
    )<TextRequest, any, Error, undefined>(),
    standardtexts: createAsyncAction(
        '@auth/standardtexts/request',
        '@auth/standardtexts/success',
        '@auth/standardtexts/failure',
        '@auth/standardtexts/cancel'
    )<string, any, Error, undefined>(),
    methods: createAsyncAction(
        '@auth/methods/request',
        '@auth/methods/success',
        '@auth/methods/failure',
        '@auth/methods/cancel'
    )<MethodsRequest, LoginMethodInfoObject[], Error, undefined>(),
    selectMethod: createAction('@auth/methods/select')<LoginMethodInfoObject>(),
    clearSelectMethod: createAction('@auth/methods/select/clear')(),
    bankIdStatus: createAsyncAction(
        '@auth/statusInfo/bankId/request',
        '@auth/statusInfo/bankId/success',
        '@auth/statusInfo/bankId/failure',
        '@auth/statusInfo/bankId/cancel'
    )<GenericRequest, StatusInfo, Error, undefined>(),
    frejaStatus: createAsyncAction(
        '@auth/statusInfo/freja/request',
        '@auth/statusInfo/freja/success',
        '@auth/statusInfo/freja/failure',
        '@auth/statusInfo/freja/cancel'
    )<GenericRequest, StatusInfo, Error, undefined>(),
    frejaOrgIdStatus: createAsyncAction(
        '@auth/statusInfo/frejaorgid/request',
        '@auth/statusInfo/frejaorgid/success',
        '@auth/statusInfo/frejaorgid/failure',
        '@auth/statusInfo/frejaorgid/cancel'
    )<GenericRequest, StatusInfo, Error, undefined>(),
    bankIdQr: createAsyncAction(
        '@auth/qr/bankId/request',
        '@auth/qr/bankId/success',
        '@auth/qr/bankId/failure',
        '@auth/qr/bankId/cancel'
    )<GenericRequest, string, Error, undefined>(),
    frejaQr: createAsyncAction(
        '@auth/qr/freja/request',
        '@auth/qr/freja/success',
        '@auth/qr/freja/failure',
        '@auth/qr/freja/cancel'
    )<GenericRequest, string, Error, undefined>(),
    frejaOrgIdQr: createAsyncAction(
        '@auth/qr/frejaorgid/request',
        '@auth/qr/frejaorgid/success',
        '@auth/qr/frejaorgid/failure',
        '@auth/qr/frejaorgid/cancel'
    )<GenericRequest, string, Error, undefined>(),
    setErrorMsg: createAction('@auth/error/set')<string | undefined>(),
    cancel: createAsyncAction(
        '@auth/cancel/request',
        '@auth/cancel/success',
        '@auth/cancel/failure',
        '@auth/cancel/cancel'
    )<GenericRequest, undefined, Error, undefined>(),
    cancelDs: createAsyncAction(
        '@auth/cancelDs/request',
        '@auth/cancelDs/success',
        '@auth/cancelDs/failure',
        '@auth/cancelDs/cancel'
    )<{ spId: string }, undefined, Error, undefined>(),
    vidps: createAsyncAction(
        '@auth/vidps/request',
        '@auth/vidps/success',
        '@auth/vidps/failure',
        '@auth/vidps/cancel'
    )<{ spId: string; lang: string }, VidpInfo[], string, undefined>(),
}
export type AuthAction = ActionType<typeof actions>

const errorReporter = getApiErrorReporter(webApiClient)

export const reducer = createReducer<AuthState, AuthAction>({
    methods: [],
    vidps: [],
    authStatus: AuthStatus.NOT_STARTED,
    statusInfo: {},
    polling: false,
    statusRetries: 0,
})
    .handleAction(actions.loadInactiveData.success, (state, action) => ({
        ...state,
        inactiveRedirectUrl: action.payload.redirectUrl,
        inactiveTimeoutMs: action.payload.timeoutMs,
    }))
    .handleAction(actions.text.success, (state, action) => ({
        ...state,
        text: action.payload,
    }))
    .handleAction(actions.lang.success, (state, action) => ({
        ...state,
        lang: action.payload,
    }))
    .handleAction(actions.standardtexts.success, (state, action) => ({
        ...state,
        standardtexts: action.payload,
    }))
    .handleAction(actions.methods.success, (state, action) => ({
        ...state,
        methods: action.payload,
    }))
    .handleAction(actions.vidps.failure, (state, action) => ({
        ...state,
        authStatus: AuthStatus.ERROR,
        errorMsg: action.payload,
    }))
    .handleAction(actions.methods.failure, failWithMessage('EM_ILLEGAL_USE_OF_AUTH_ATTEMPT'))
    .handleAction(actions.selectMethod, (state, action) => ({
        ...state,
        selectedMethod: action.payload,
    }))
    .handleAction(actions.clearSelectMethod, (state, action) => ({
        ...state,
        selectedMethod: undefined,
    }))
    .handleAction(actions.bankIdStatus.success, statusSuccess())
    .handleAction(actions.bankIdStatus.failure, statusFailure())
    .handleAction(actions.frejaStatus.success, statusSuccess())
    .handleAction(actions.frejaStatus.failure, statusFailure())
    .handleAction(actions.frejaOrgIdStatus.success, statusSuccess())
    .handleAction(actions.frejaOrgIdStatus.failure, statusFailure())
    .handleAction(actions.cancel.success, (state) => ({
        ...state,
        authStatus: AuthStatus.CANCELLED,
    }))
    .handleAction(actions.cancel.failure, (state) => ({
        ...state,
        authStatus: AuthStatus.ERROR,
        errorMsg: 'EM_USER_CANCEL',
    }))
    .handleAction(actions.setErrorMsg, (state, action) => ({
        ...state,
        errorMsg: action.payload,
    }))
    .handleAction(actions.setStatus, (state, action) => ({
        ...state,
        authStatus: action.payload,
    }))
    .handleAction(actions.setStatusInfo, (state, action) => ({
        ...state,
        statusInfo: action.payload,
    }))
    .handleAction(actions.setPolling, (state, action) => ({
        ...state,
        polling: action.payload,
    }))
    .handleAction(actions.incrementStatusRetries, (state) => ({
        ...state,
        statusRetries: state.statusRetries + 1,
    }))
    .handleAction(actions.resetStatusRetries, (state) => ({
        ...state,
        statusRetries: 0,
        polling: false,
    }))
    .handleAction(actions.resetAfterError, (state) => ({
        ...state,
        authStatus: AuthStatus.NOT_STARTED,
        statusInfo: {},
        selectedMethod: undefined,
        errorMsg: undefined,
        qrValue: undefined,
    }))
    .handleAction(actions.vidps.success, (state, action) => ({
        ...state,
        vidps: action.payload,
    }))
    .handleAction(actions.bankIdQr.success, (state, action) => ({
        ...state,
        qrValue: action.payload,
    }))
    .handleAction(actions.frejaQr.success, (state, action) => ({
        ...state,
        qrValue: action.payload,
    }))
    .handleAction(actions.frejaOrgIdQr.success, (state, action) => ({
        ...state,
        qrValue: action.payload,
    }))

const validUrl = (url: string) => {
    try {
        new URL(url)
        return true
    } catch (_) {
        return false
    }
}

export const saga = function* (history: History) {
    yield takeLatest(actions.userInactive, function* (action) {
        const redirectUrl: string | undefined = yield select(
            (state: AppState) => state.auth.inactiveRedirectUrl
        )
        if (redirectUrl) {
            window.location.replace(redirectUrl)
        } else {
            yield put(actions.setErrorMsg('EM_USER_INACTIVE'))
            history.push('../error')
        }
    })
    yield takeLatest(actions.loadInactiveData.request, function* (action) {
        try {
            const inactiveInfo: Object = yield webApiClient.getInactiveInfo(action.payload)
            yield put(actions.loadInactiveData.success(inactiveInfo))
        } catch (error: any) {
            yield put(actions.loadInactiveData.failure(error))
            yield errorReporter('saga.loadInactiveData.request', error)
        }
    })
    yield takeLatest(actions.lang.request, function* (action) {
        try {
            const supportedLangs: string[] = yield webApiClient.getSupportedLangs()
            yield put(actions.lang.success(chooseLang(supportedLangs)))
        } catch (error: any) {
            yield put(actions.lang.failure(error))
            yield errorReporter('saga.lang.request', error)
        }
    })
    yield takeLatest(actions.text.request, function* (action) {
        try {
            const list: Object = yield webApiClient.getText(
                action.payload.spId,
                action.payload.lang
            )
            yield put(actions.text.success(list))
        } catch (error: any) {
            yield put(actions.text.failure(error))
            yield errorReporter('saga.text.request', error)
        }
    })
    yield takeLatest(actions.standardtexts.request, function* (action) {
        try {
            const list: Object = yield webApiClient.getStandardTexts(action.payload)
            yield put(actions.standardtexts.success(list))
        } catch (error: any) {
            yield put(actions.standardtexts.failure(error))
            errorReporter('saga.standardtexts.request', error)
        }
    })
    yield takeLatest(actions.selectMethod, function* (action) {
        try {
            const method = action.payload
            const connectionType = method.connectionType
            const config = method.parameters
            var urlParams = new URLSearchParams(window.location.search)
            var aid = urlParams.get('aid')
            var lang = urlParams.get('lang')

            switch (connectionType) {
                case 'freja': 
                case 'frejaorgid':
                case 'nias': {
                    const useQr = (loginParameters: any) =>
                        (loginParameters?.loginMethod === 'qr' ||
                            loginParameters?.loginMethod === 'qr_and_pnr' ||
                            loginParameters?.loginMethod === 'qr_or_pnr' ||
                            loginParameters?.loginMethod === 'qr_and_text_input' ||
                            loginParameters?.loginMethod === 'qr_or_text_input')

                    const usePn = (loginParameters: any) =>
                        loginParameters.loginMethod === 'pnr' ||
                        loginParameters.loginMethod === 'text_input' ||
                        loginParameters.loginMethod === 'qr_and_pnr' ||
                        loginParameters.loginMethod === 'qr_and_text_input'

                    const loginType = useQr(config) ? 'qr/' : usePn(config) ? 'pn/' : ''

                    history.push(`/${connectionType}/${method.id}/${loginType}`)
                    break
                }
                //Separate case for bankid as PNR shouldn't be used at all (and isn't used by the tech team)
                //Worst case defaults to QR if pnr was set.
                case 'bankid': {
                    const useQr = (loginParameters: any) =>
                        (loginParameters?.loginMethod !== 'na')
                    const loginType = useQr(config) ? 'qr/' : ''
                    history.push(`/${connectionType}/${method.id}/${loginType}`)
                    break
                    }
                case 'mtls': {
                    redirectToMtls(config, aid, method.id!)
                    break
                }
                case 'oidc': {
                    window.location.replace(
                        `${baseUrl}/id/proxy/oidc/auth/?aid=${aid}&id=${method.id!}&lang=${lang}`
                    )
                    break
                }
                case 'saml2': {
                    window.location.replace(
                        `${baseUrl}/id/proxy/saml2/auth/?aid=${aid}&id=${method.id!}&lang=${lang}`
                    )
                    break
                }
                default:
                    history.push(`/${connectionType}/${method.id}/`)
            }
        } catch (error: any) {
            yield errorReporter('saga.actions.method.select', error)
            console.log(error)
        }
    })
    yield takeLatest(actions.methods.request, function* (action) {
        try {
            const status: string = yield webApiClient.getStatus(
                action.payload.aid,
                action.payload.spId
            )
            if(status === "COMPLETE"){
                yield put(actions.setErrorMsg('EM_ILLEGAL_USE_OF_AUTH_ATTEMPT'))
                yield put(actions.setStatus(AuthStatus.COMPLETED))
                history.push('../error')
            }
            else{
                const list: LoginMethodInfoObject[] = yield webApiClient.getMethods(
                    action.payload.aid,
                    action.payload.lang,
                    action.payload.spId
                )

                const filteredList = list.filter((i) => !(i.connectionType === 'mtls' && isMobile))
                yield put(actions.methods.success(filteredList))

                if (filteredList.length === 1) {
                    yield put(actions.selectMethod(filteredList[0]))
                }
                if (filteredList.length === 0) {
                    put(actions.setErrorMsg('EM_NO_LOGIN_METHODS'))
                    put(actions.setStatus(AuthStatus.ERROR))
                }
            }
        } catch (error: any) {
            let url = ''
            yield put(actions.methods.failure(error))
            if (error.status === 410) {
                error.text().then((i: any) => window.location.replace(i))
            } else {
                yield errorReporter('saga.actions.methods.request', error)
            }
        }
    })
    yield takeLatest(actions.bankIdStatus.request, function* (action) {
        try {
            yield put(actions.setPolling(true))
            const statusInfo: StatusInfo = yield bankIdClient.getBankIdStatus(action.payload.aid)
            yield put(actions.resetStatusRetries())
            if (statusInfo) {
                if (statusInfo.sendAuthnFailed) {
                    window.location.replace(baseUrl + '/id/fail?aid=' + action.payload.aid)
                    return; //SEEID-572 Because we don't want the error to be shown if it a AuthnFailed gets sent, dont update status in browser.
                }
                if (statusInfo.status === 'complete') {
                    yield put(actions.setStatus(AuthStatus.COMPLETED))
                    window.location.replace(baseUrl + '/id/finish?aid=' + action.payload.aid)
                }
            }
            yield put(actions.bankIdStatus.success(statusInfo))
        } catch (error: any) {
            yield put(actions.setPolling(false))
            yield put(actions.incrementStatusRetries())
            yield errorReporter('saga.actions.bankIdStatus.request', error)
        }
    })
    yield takeLatest(actions.frejaStatus.request, function* (action) {
        try {
            yield put(actions.setPolling(true))
            const statusInfo: StatusInfo = yield frejaClient.getFrejaStatus(action.payload.aid)
            yield put(actions.resetStatusRetries())
            if (statusInfo) {
                if (statusInfo.sendAuthnFailed) {
                    window.location.replace(baseUrl + '/id/fail?aid=' + action.payload.aid)
                    return; //SEEID-572 Because we don't want the error to be shown if it a AuthnFailed gets sent, dont update status in browser.
                }
                if (statusInfo.status === 'complete') {
                    yield put(actions.setStatus(AuthStatus.COMPLETED))
                    window.location.replace(baseUrl + '/id/finish?aid=' + action.payload.aid)
                }
            }
            yield put(actions.frejaStatus.success(statusInfo))
        } catch (error: any) {
            yield put(actions.setPolling(false))
            yield put(actions.incrementStatusRetries())
            yield errorReporter('saga.actions.frejaStatus.request', error)
        }
    })
    yield takeLatest(actions.frejaOrgIdStatus.request, function* (action) {
        try {
            yield put(actions.setPolling(true))
            const statusInfo: StatusInfo = yield frejaOrgIdClient.getFrejaStatus(action.payload.aid)
            yield put(actions.resetStatusRetries())
            if (statusInfo) {
                if (statusInfo.sendAuthnFailed) {
                    window.location.replace(baseUrl + '/id/fail?aid=' + action.payload.aid)
                    return; //SEEID-572 Because we don't want the error to be shown if it a AuthnFailed gets sent, dont update status in browser.
                }
                if (statusInfo.status === 'complete') {
                    yield put(actions.setStatus(AuthStatus.COMPLETED))
                    window.location.replace(baseUrl + '/id/finish?aid=' + action.payload.aid)
                }
            }
            yield put(actions.frejaOrgIdStatus.success(statusInfo))
        } catch (error: any) {
            yield put(actions.setPolling(false))
            yield put(actions.incrementStatusRetries())
            yield errorReporter('saga.actions.frejaOrgIdStatus.request', error)
        }
    })
    yield takeLatest(actions.cancel.request, function* (action) {
        try {
            const cancelInfo: CancelInfo = yield idClient.cancel(action.payload.aid)
            if (cancelInfo?.redirectURL) {
                window.location.replace(cancelInfo.redirectURL)
            } else {
                yield put(actions.cancel.success())
                window.location.replace(baseUrl + '/id/fail?aid=' + action.payload.aid)
            }
        } catch (error: any) {
            yield put(actions.cancel.failure(error))
            yield errorReporter('saga.actions.cancel.request', error)
            history.push('../error')
        }
    })
    yield takeLatest(actions.cancelDs.request, function* (action) {
        try {
            const cancelInfo: CancelInfo = yield idClient.cancelDs(action.payload.spId)
            if (cancelInfo?.redirectURL) {
                window.location.replace(cancelInfo.redirectURL)
            }
        } catch (error: any) {
            yield put(actions.cancelDs.failure(error))
            yield errorReporter('saga.actions.cancelIDs.request', error)
            history.push('../error')
        }
    })

    yield takeLatest(actions.vidps.request, function* (action) {
        try {
            const searchParams = new URLSearchParams(window.location.search)
            const fetchForCustomer = searchParams.get('fetchForCustomer') ?? ''
            const returnUrl = searchParams.get('returnUrl') ?? ''

            if (returnUrl != '') {
                if (!validUrl(returnUrl)) {
                    yield put(actions.vidps.failure('EM_INVALID_RETURN_URL'))
                }
            }

            const returnIdParam = searchParams.get('returnIdParam') ?? undefined
            const refId = searchParams.get('refId') ?? ''
            const list: VidpInfo[] = yield webApiClient.getVidps(
                action.payload.spId,
                action.payload.lang,
                fetchForCustomer === 'true' ? true : false,
                returnUrl,
                refId,
                returnIdParam
            )
            if (list.length === 1) {
                window.location.replace(list[0].redirectUrl)
            } else {
                yield put(actions.vidps.success(list))
            }
        } catch (error: any) {
            yield put(actions.vidps.failure('EM_UNKNOWN_ERROR'))
            yield errorReporter('saga.actions.vidps.request', error)
        }
    })
    yield takeLatest(actions.bankIdQr.request, function* (action) {
        try {
            const value: string = yield bankIdClient.getBankIdQrValue(action.payload.aid)
            yield put(actions.bankIdQr.success(value))
        } catch (error: any) {
            yield put(actions.bankIdQr.failure(error))
            yield errorReporter('saga.actions.bankIdQr.request', error)
        }
    })
    yield takeLatest(actions.frejaQr.request, function* (action) {
        try {
            const value: string = yield frejaClient.getFrejaQrValue(action.payload.aid)
            yield put(actions.frejaQr.success(value))
        } catch (error: any) {
            yield put(actions.frejaQr.failure(error))
            yield errorReporter('saga.actions.frejaQr.request', error)
        }
    })
    yield takeLatest(actions.frejaOrgIdQr.request, function* (action) {
        try {
            const value: string = yield frejaOrgIdClient.getFrejaQrValue(action.payload.aid)
            yield put(actions.frejaOrgIdQr.success(value))
        } catch (error: any) {
            yield put(actions.frejaOrgIdQr.failure(error))
            yield errorReporter('saga.actions.frejaOrgIdQr.request', error)
        }
    })
}

function failWithMessage(message: string) {
    return (state: AuthState) => ({
        ...state,
        authStatus: AuthStatus.ERROR,
        errorMsg: message,
    })
}
function statusFailure() {
    return (state: AuthState, action: { payload: any }) => ({
        ...state,
        authStatus: AuthStatus.ERROR,
        errorMsg: action.payload.message,
    })
}

function statusSuccess() {
    return (state: AuthState, action: { payload: any }) => ({
        ...state,
        statusInfo: action.payload,
    })
}

const chooseLang = (supportedLangs: string[]) => {
    const browserLang = navigator.languages[0].substr(0, 2)
    const lang = new URLSearchParams(window.location.search).get('lang') || browserLang
    const langToChoose = lang === 'auto' ? browserLang : lang
    return supportedLangs.includes(langToChoose) ? langToChoose : 'en'
}

const redirectToMtls = (config: any, aid: string | null, methodId: string) => {
    if (config && config.hasOwnProperty('subdomain')) {
        const subDomain = config.subdomain as string
        // new host is current host with subdomain prefix
        const scheme = window.location.protocol
        const host = window.location.host
        window.location.replace(
            `${scheme}//${subDomain}.${host}/id/mtls/auth/?aid=${aid}&loginMethodId=${methodId}`
        )
    }
}
