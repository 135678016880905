/* eslint-disable import/no-anonymous-default-export */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { BaseLoginComponent as LoginContainer } from '../../components/LoginContainer'
import { LoginMethodInfoObject } from '../../generated/api'
import { frejaOrgIdClient } from '../../saga/api'
import { actions, AuthStatus } from '../../saga/auth'
import { AppState } from '../../saga/store'

import LoginFrejaOrgId from './LoginFrejaOrgId'


interface LoginFrejaOrgIdProps {
    aid: string
    spId: string
    pnrMode: boolean
    qrMode: boolean
    lang: string
    loginMethodId: string
}

export default (props: LoginFrejaOrgIdProps) => {
    const { aid, spId, lang, loginMethodId } = { ...props }
    const dispatch = useDispatch()
    const history = useHistory()
    const status = useSelector<AppState, AuthStatus>((s) => s.auth.authStatus)
    const loginMethods = useSelector((s: AppState) => s.auth.methods)
    const selectedMethod = useSelector<AppState, LoginMethodInfoObject | undefined>(
        (s) => s.auth.selectedMethod
    )

    useEffect(() => {
        if (loginMethods.length != 0) {
            if (!selectedMethod) {
                const match = loginMethods.find((x) => x.id === loginMethodId)
                if (match) {
                    dispatch(actions.selectMethod(match))
                }
            }
        } else {
            dispatch(actions.methods.request({ aid, lang, spId }))
        }
    }, [aid, lang, spId, loginMethodId, loginMethods, selectedMethod, dispatch])

    
    /**
     * Sends a cancel request to the Freja OrgID controller and awaits the response before updating the history.
     */
    const cancelConnectorSession = async () => {
        // A request is not made until a personal number is entered. 
        // This if-case ensures a user can still use the back button from the "enter personal number" page
        if(selectedMethod && status == AuthStatus.NOT_STARTED) {
            history.push('../../../')
        }

        if (selectedMethod && (status == AuthStatus.INITIATED || status == AuthStatus.PENDING)) {
            try {
                await frejaOrgIdClient.cancelFrejaSession(aid);
                history.push('../../../')
            } catch (error) {
                dispatch(actions.setStatus(AuthStatus.ERROR))
            }
        }
    }

    const handleBack = async () => {
        await cancelConnectorSession()
        dispatch(actions.clearSelectMethod())
        dispatch(actions.setStatus(AuthStatus.NOT_STARTED))
        dispatch(actions.setStatusInfo({}))
    }
    
    /**
     * State to only allow the handleBack function to be called once.
     * This is to prevent a user from spamming the "back" button and repeatedly sending cancel requests to core.
     * It is also used to control which components that gets rendered in the FrejaOrgId component.
     */
    const [allowHandleBack, setAllowHandleBack] = useState(true);

    return (
        <LoginContainer
            aid={aid}
            lang={lang}
            spId={spId}
            renderBack={true}
            icon={selectedMethod?.iconBytes}
            handleCancel={cancelConnectorSession}
            handleBack={() => {
                if(allowHandleBack) {
                    setAllowHandleBack(false);
                    handleBack()
                }
            }}>
            {
                /* Pass the allowHandleBack state to LoginFrejaOrgId component to prevent rendering of some components
                    if allowHandleBack is set to false, as only a circular progress bar should be shown.
                */
            }
            {selectedMethod && <LoginFrejaOrgId {...props} allowHandleBack={allowHandleBack} />}
        </LoginContainer>
    )
}
