/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginMethodInfoObject
 */
export interface LoginMethodInfoObject {
    /**
     * 
     * @type {string}
     * @memberof LoginMethodInfoObject
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginMethodInfoObject
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof LoginMethodInfoObject
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof LoginMethodInfoObject
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginMethodInfoObject
     */
    connectionType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoginMethodInfoObject
     */
    signMesssage?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoginMethodInfoObject
     */
    providedIdentification?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginMethodInfoObject
     */
    iconBytes?: string;
    /**
     * 
     * @type {object}
     * @memberof LoginMethodInfoObject
     */
    parameters?: object;
}

export function LoginMethodInfoObjectFromJSON(json: any): LoginMethodInfoObject {
    return LoginMethodInfoObjectFromJSONTyped(json, false);
}

export function LoginMethodInfoObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginMethodInfoObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'connectionType': !exists(json, 'connectionType') ? undefined : json['connectionType'],
        'signMesssage': !exists(json, 'signMesssage') ? undefined : json['signMesssage'],
        'providedIdentification': !exists(json, 'providedIdentification') ? undefined : json['providedIdentification'],
        'iconBytes': !exists(json, 'iconBytes') ? undefined : json['iconBytes'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
    };
}

export function LoginMethodInfoObjectToJSON(value?: LoginMethodInfoObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'order': value.order,
        'description': value.description,
        'connectionType': value.connectionType,
        'signMesssage': value.signMesssage,
        'providedIdentification': value.providedIdentification,
        'iconBytes': value.iconBytes,
        'parameters': value.parameters,
    };
}


