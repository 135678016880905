import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BaseLoginComponent as LoginContainer, LoginProps } from '../../components/LoginContainer'
import { SelectLoginMethod } from '../../components/SelectLoginMethod'
import { LoginMethodInfoObject } from '../../generated/api'
import { actions } from '../../saga/auth'
import { AppState } from '../../saga/store'

const Login = (props: LoginProps) => {
    const dispatch = useDispatch()
    const { aid, lang, spId } = { ...props }
    const loginMethods = useSelector((s: AppState) => s.auth.methods)

    useEffect(() => {
        dispatch(actions.methods.request({ aid, lang, spId }))
    }, [aid, lang, spId])

    const handleSelect = (method: LoginMethodInfoObject) => {
        dispatch(actions.selectMethod(method))
    }

    return (
        <LoginContainer aid={aid} lang={lang} spId={spId} renderBack={false}>
           <SelectLoginMethod loginMethods={loginMethods} onSelect={handleSelect} />
        </LoginContainer>
    )
}
export default Login
