import { Button, CircularProgress, Grid } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { LoginMethodInfoObject } from '../generated/api'

export interface SelectLoginMethodProps {
    loginMethods: LoginMethodInfoObject[]
    onSelect: (selected: LoginMethodInfoObject) => void
}

export const SelectLoginMethod = (props: SelectLoginMethodProps) => {
    return (
        <Grid container spacing={1}>
            <Grid className="Centred-Content" item xs={12}>
                {props.loginMethods.length === 0 && <CircularProgress />}
                {props.loginMethods.length != 0 && <span className="idp-header idp-header-info">
                    <FormattedMessage id="IN_CHOOSE_LOGIN_METHOD" /></span>
                }
            </Grid>
            {props.loginMethods.map((m) => (
                <Grid key={m.id} item xs={12}>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => props.onSelect(m)}
                        className={`idp-btn idp-btn-select-login-method idp-btn-select-login-method-${m.connectionType ?? 'unknown'}`}
                        startIcon={<img style={{ height: '25px' }} src={m.iconBytes} />}
                        endIcon={<ChevronRightIcon />}>
                        <Grid container justifyContent="flex-start">
                            <Grid item>{m.description}</Grid>
                        </Grid>
                    </Button>
                </Grid>
            ))}
        </Grid>
    )
}
