import { useEffect, useMemo, useState } from 'react'
import { isDesktop, isMobileSafari } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import HsaIdInput from '../../components/HsaIdInput'
import InfoMessage from '../../components/InfoMessage'
import { BaseLoginComponent as LoginContainer } from '../../components/LoginContainer'
import PersonalNumberInput from '../../components/PersonalNumberInput'
import StartNiasAppButton from '../../components/StartIdAppButton'
import Status from '../../components/Status'
import ToggleQrPnrButton from '../../components/ToggleQrPnrButton'
import { LoginMethodInfoObject, StatusInfo } from '../../generated/api'
import { actions, AuthStatus } from '../../saga/auth'
import { AppState } from '../../saga/store'
import { replaceLastPath } from '../../util/locationUtil'

interface LoginNiasProps {
    aid: string
    spId: string
    pnrMode: boolean
    qrMode: boolean
    lang: string
    loginMethodId: string
}

export default (props: LoginNiasProps) => {
    const { aid, spId, lang, pnrMode, qrMode, loginMethodId } = { ...props }
    const dispatch = useDispatch()
    const history = useHistory()
    const [autostarttoken, setAutoStartToken] = useState<string | undefined>(undefined)
    const selectedMethod = useSelector<AppState, LoginMethodInfoObject | undefined>(
        (s) => s.auth.selectedMethod
    )

    useEffect(() => {
        if (!pnrMode || selectedMethod?.providedIdentification) {
            handleLogin(selectedMethod?.providedIdentification)
        }
    }, [selectedMethod, pnrMode])

    const config: any = selectedMethod?.parameters
    const sameDevice = useMemo(() => config?.device === 'true', [config?.device])
    const tryAutostart = useMemo(() => config?.tryAutostart === 'true', [config?.tryAutostart])
    const pNrValidation = useMemo(() => config?.textInputType === 'pn', [config?.textInputType])

    const handleLogin = (personalNumber: string | undefined) => {
        if (!selectedMethod) {
            return
        }
        dispatch(actions.setStatus(AuthStatus.INITIATED))
    }
    const handleBack = () => {
        dispatch(actions.clearSelectMethod())
        dispatch(actions.setStatus(AuthStatus.NOT_STARTED))
        dispatch(actions.setStatusInfo({}))
        history.push('../../../')
    }

    const toggleQrPnr = () => {
        dispatch(actions.setStatus(AuthStatus.NOT_STARTED))
        dispatch(actions.setStatusInfo({}))
        const newPath = !qrMode ? '/qr/' : '/pn/'
        history.push(replaceLastPath(history.location.pathname, newPath))
    }
    const handleStatusMessage = (statusInfo: StatusInfo) => {
        return statusInfo.message ? statusInfo.message : ''
    }
    const hideQr = (statusInfo: StatusInfo) => {
        if (!statusInfo.message) {
            return false
        } else {
            return true
        }
    }
    const getStartBankIdAppUrl = (token: string | undefined) => {
        var url = isDesktop || isMobileSafari ? 'nias:///?' : 'https://app.nias.com/?'
        if (token) {
            url = url + 'autostarttoken=' + token + '&'
        }
        return url + 'redirect=null'
    }

    return (
        <LoginContainer
            aid={aid}
            lang={lang}
            spId={spId}
            renderBack={true}
            icon={selectedMethod?.iconBytes}
            handleBack={handleBack}>
            {selectedMethod && (
                <>
                    {pnrMode && pNrValidation && (
                        <PersonalNumberInput
                            defaultPersonalNumber={selectedMethod?.providedIdentification}
                            handleLogin={handleLogin}
                        />
                    )}
                    {pnrMode && !pNrValidation && (
                        <HsaIdInput
                            defaultHsaId={selectedMethod?.providedIdentification}
                            handleLogin={handleLogin}
                        />
                    )}
                    <InfoMessage
                        qr={qrMode}
                        sameDevice={sameDevice}
                        loggedInMessage="IN_NIAS_LOGGED_IN"
                        scanQrMessage="IN_NIAS_SCAN_QR"
                        hideQrMessage={hideQr}
                        textClassName="idp-header idp-header-info"
                    />
                    <Status
                        aid={props.aid}
                        qr={qrMode ? 'ANIMATED' : undefined} // ANIMATED or STATIC?
                        handleStatusMessage={handleStatusMessage}
                        statusAction={actions.bankIdStatus}
                        qrAction={actions.bankIdQr}
                        hideQr={hideQr}
                    />
                    <StartNiasAppButton
                        sameDevice={sameDevice}
                        getStartIdAppUrl={getStartBankIdAppUrl}
                        autostarttoken={autostarttoken}
                        autoStartConfig={undefined}
                        tryAutostart={tryAutostart}
                        buttonLabel="IN_NIAS_START_THE_APP"
                        type="nias"
                    />
                    {config.loginMethod === 'qr_or_text_input' && (
                        <ToggleQrPnrButton qr={qrMode} toggleQrPnr={toggleQrPnr} />
                    )}
                </>
            )}
        </LoginContainer>
    )
}
