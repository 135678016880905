/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VidpIcon
 */
export interface VidpIcon {
    /**
     * 
     * @type {string}
     * @memberof VidpIcon
     */
    url: string;
    /**
     * 
     * @type {number}
     * @memberof VidpIcon
     */
    width: number;
    /**
     * 
     * @type {number}
     * @memberof VidpIcon
     */
    height: number;
}

export function VidpIconFromJSON(json: any): VidpIcon {
    return VidpIconFromJSONTyped(json, false);
}

export function VidpIconFromJSONTyped(json: any, ignoreDiscriminator: boolean): VidpIcon {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
        'width': json['width'],
        'height': json['height'],
    };
}

export function VidpIconToJSON(value?: VidpIcon | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'width': value.width,
        'height': value.height,
    };
}


